.menu-bottom-component-menu-bottom-component {
  width: 100%;
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: column;
}
.menu-bottom-component-container {
  color: var(--dl-color-gray-white);
  width: 100%;
  height: 490px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background-color: #100F0F;
}
.menu-bottom-component-container01 {
  gap: 40px;
  width: 80%;
  height: 385px;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.menu-bottom-component-container02 {
  width: auto;
  height: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: space-between;
}
.menu-bottom-component-container03 {
  gap: var(--dl-space-space-p20);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.menu-bottom-component-container04 {
  flex: 0 0 auto;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  border-radius: var(--dl-radius-radius-radius8);
  justify-content: center;
  background-color: var(--dl-color-gray-white);
}
.menu-bottom-component-navlink {
  display: contents;
}
.menu-bottom-component-image {
  width: 100%;
  object-fit: cover;
  text-decoration: none;
}
.menu-bottom-component-container05 {
  gap: 20px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.menu-bottom-component-container06 {
  gap: 10px;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.menu-bottom-component-container07 {
  flex: 0 0 auto;
  width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  border-radius: var(--dl-radius-radius-round);
  flex-direction: column;
  justify-content: center;
  background-color: var(--dl-color-gray-white);
}
.menu-bottom-component-icon {
  width: 24px;
  height: 24px;
}
.menu-bottom-component-container08 {
  gap: 150px;
  flex: 1;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
}
.menu-bottom-component-container09 {
  gap: 30px;
  flex: 0 0 auto;
  width: auto;
  height: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.menu-bottom-component-navlink1 {
  cursor: pointer;
  opacity: 0.4;
  transition: 0.1s;
  text-decoration: none;
}
.menu-bottom-component-navlink1:hover {
  opacity: 1;
}
.menu-bottom-component-navlink2 {
  cursor: pointer;
  opacity: 0.4;
  transition: 0.1s;
  text-decoration: none;
}
.menu-bottom-component-navlink2:hover {
  opacity: 1;
}
.menu-bottom-component-navlink3 {
  cursor: pointer;
  opacity: 0.4;
  transition: 0.1s;
  text-decoration: none;
}
.menu-bottom-component-navlink3:hover {
  opacity: 1;
}
.menu-bottom-component-navlink4 {
  cursor: pointer;
  opacity: 0.4;
  transition: 0.1s;
  text-decoration: none;
}
.menu-bottom-component-navlink4:hover {
  opacity: 1;
}
.menu-bottom-component-container10 {
  gap: 30px;
  flex: 0 0 auto;
  width: auto;
  height: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.menu-bottom-component-navlink5 {
  cursor: pointer;
  opacity: 0.4;
  transition: 0.1s;
  text-decoration: none;
}
.menu-bottom-component-navlink5:hover {
  opacity: 1;
}
.menu-bottom-component-navlink6 {
  cursor: pointer;
  opacity: 0.4;
  transition: 0.1s;
  text-decoration: none;
}
.menu-bottom-component-navlink6:hover {
  opacity: 1;
}
.menu-bottom-component-navlink7 {
  cursor: pointer;
  opacity: 0.4;
  transition: 0.1s;
  text-decoration: none;
}
.menu-bottom-component-navlink7:hover {
  opacity: 1;
}
.menu-bottom-component-container11 {
  gap: 30px;
  flex: 0 0 auto;
  width: auto;
  height: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.menu-bottom-component-container12 {
  flex: 0 0 auto;
  width: 100%;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #1B1B1B;
}
.menu-bottom-component-container13 {
  flex: 0 0 auto;
  color: var(--dl-color-gray-white);
  width: 80%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}









@media(max-width: 1440px) {
  .menu-bottom-component-container04 {
    width: 61px;
    height: 61px;
    align-items: center;
    justify-content: center;
    background-color: #ffffff;
  }
  .menu-bottom-component-image {
    width: 80%;
    height: 80%;
  }
}
