.home-container {
  gap: var(--dl-space-space-halfunit);
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.home-web {
  flex: 1;
  width: 100%;
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: column;
}
.home-container001 {
  color: var(--dl-color-gray-white);
  width: 100%;
  height: 740px;
  display: flex;
  overflow: hidden;
  align-items: center;
  flex-direction: column;
  background-size: cover;
  background-image: url("/bg/img_9.svg");
  background-repeat: no-repeat;
}
.home-container002 {
  flex: 1;
  width: 100%;
  height: 0%;
  display: flex;
  align-items: center;
  padding-left: var(--dl-space-space-p60);
  flex-direction: row;
  justify-content: space-between;
}
.home-container003 {
  gap: 60px;
  flex: 1;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.home-container004 {
  gap: 20px;
  width: 100%;
  height: auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-container005 {
  color: var(--dl-color-gray-text);
  display: flex;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
  align-items: flex-start;
  padding-top: 5px;
  padding-left: 15px;
  border-radius: 20px;
  padding-right: 15px;
  flex-direction: column;
  padding-bottom: 5px;
  background-image: linear-gradient(315deg, rgba(255, 255, 255, 0.5) 0.00%,rgb(255, 255, 255) 99.00%);
}
.home-text {
  font-style: normal;
  font-weight: 600;
}
.home-text001 {
  text-shadow: 2px 2px rgba(0, 0, 0, 0.2);
}
.home-text002 {
  opacity: 1;
  text-shadow: 1px 1px rgba(0, 0, 0, 0.3);
}
.home-container006 {
  gap: 20px;
  width: 659px;
  display: flex;
  flex-direction: column;
}
.home-container007 {
  flex: 1;
  height: 100%;
  display: flex;
  z-index: 90;
  position: relative;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.home-image {
  width: 738px;
  height: 586px;
  object-fit: cover;
}
.home-container008 {
  gap: 10px;
  width: 100%;
  display: flex;
  z-index: 90;
  box-shadow: 0px 0px 10px 0px rgba(66, 66, 66, 0.3);
  align-items: center;
  border-color: var(--dl-color-gray-border-2);
  border-width: 1px;
  flex-direction: column;
  padding-bottom: var(--dl-space-space-p20);
  justify-content: flex-end;
  border-left-width: 0px;
  border-right-width: 0px;
}
.home-container009 {
  flex: 0 0 auto;
  width: 100%;
  height: 20px;
  display: flex;
  box-shadow: 0px -5px 10px 0px rgba(66, 66, 66, 0.3);
  align-items: flex-start;
  border-color: var(--dl-color-gray-border);
  border-width: 1px;
  background-color: rgba(243, 243, 243, 0.63);
  border-left-width: 0px;
  border-right-width: 0px;
}
.home-text003 {
  opacity: 0.6;
}
.home-container010 {
  gap: 40px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.home-text004 {
  font-size: 20px;
}
.home-text005 {
  font-size: 20px;
}
.home-text006 {
  font-size: 20px;
}
.home-text007 {
  font-size: 20px;
}
.home-text008 {
  font-size: 20px;
}
.home-text009 {
  font-size: 20px;
}
.home-text010 {
  font-size: 20px;
}
.home-text011 {
  font-size: 20px;
}
.home-container011 {
  gap: 60px;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-p60);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-p60);
  background-image: linear-gradient(0deg, rgba(189, 195, 199, 0) 0.00%,rgb(232, 240, 255) 100.00%);
}
.home-container012 {
  width: 80%;
  height: auto;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.home-container013 {
  gap: 10px;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.home-text012 {
  text-align: center;
}
.home-text016 {
  text-align: center;
}
.home-container014 {
  gap: 40px;
  width: 80%;
  height: auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-container015 {
  gap: 40px;
  width: 100%;
  height: 300px;
  display: flex;
  align-items: flex-start;
}
.home-container016 {
  gap: 20px;
  flex: 1;
  height: 100%;
  display: none;
  align-items: flex-start;
  padding-left: 40px;
  border-radius: var(--dl-radius-radius-radius8);
  padding-right: 40px;
  flex-direction: column;
  padding-bottom: var(--dl-space-space-p20);
  background-color: var(--dl-color-gray-white);
}
.home-container017 {
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.home-container018 {
  fill: var(--dl-color-gray-white);
  flex: 0 0 auto;
  width: 70px;
  height: 70px;
  display: none;
  overflow: hidden;
  align-items: center;
  border-radius: var(--dl-radius-radius-round);
  flex-direction: column;
  justify-content: center;
  background-color: var(--dl-color-primary-blue);
}
.home-icon {
  width: 60%;
  height: 60%;
}
.home-container019 {
  fill: var(--dl-color-gray-white);
  flex: 0 0 auto;
  width: 70px;
  height: 70px;
  display: flex;
  overflow: hidden;
  align-items: center;
  border-radius: var(--dl-radius-radius-round);
  flex-direction: column;
  justify-content: center;
  background-color: rgba(0, 102, 252, 0.08);
}
.home-lottie-node {
  width: 60%;
  height: 60%;
}
.home-text020 {
  font-size: 70px;
}
.home-container020 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: flex-start;
}
.home-container021 {
  flex: 0 0 auto;
  width: 100%;
  height: 1px;
  display: flex;
  align-items: flex-start;
  background-color: #DEDEDE;
}
.home-container022 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: flex-start;
}
.home-text025 {
  width: 100%;
}
.home-container024 {
  width: 100%;
  height: 100px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
.home-lottie-node1 {
  width: 84px;
  height: 89px;
}
.home-text033 {
  width: 100%;
}
.home-container026 {
  width: 100%;
  height: 100px;
  display: flex;
  align-items: flex-start;
  padding-left: 17px;
  flex-direction: column;
  justify-content: center;
}
.home-lottie-node2 {
  width: 45px;
  height: 45px;
}
.home-text041 {
  width: 100%;
}
.home-container028 {
  width: 100%;
  height: 100px;
  display: flex;
  align-items: flex-start;
  padding-left: 17px;
  flex-direction: column;
  justify-content: center;
}
.home-container029 {
  width: 50px;
  height: 50px;
  display: flex;
  overflow: hidden;
  align-items: center;
  border-radius: var(--dl-radius-radius-round);
  flex-direction: column;
  justify-content: center;
}
.home-text046 {
  width: 100%;
}
.home-container030 {
  gap: 20px;
  flex: 1;
  height: 100%;
  display: none;
  align-items: flex-start;
  padding-left: 40px;
  border-radius: var(--dl-radius-radius-radius8);
  padding-right: 40px;
  flex-direction: column;
  padding-bottom: var(--dl-space-space-p20);
  background-color: var(--dl-color-gray-white);
}
.home-container031 {
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.home-container032 {
  flex: 0 0 auto;
  width: 116px;
  height: 116px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  background-size: cover;
  background-image: url("/group%201000005158%20(1");
}
.home-container033 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: flex-start;
}
.home-container034 {
  flex: 0 0 auto;
  width: 100%;
  height: 1px;
  display: flex;
  align-items: flex-start;
  background-color: #DEDEDE;
}
.home-container035 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: flex-start;
}
.home-text052 {
  width: 100%;
}
.home-container036 {
  gap: 20px;
  flex: 1;
  height: 100%;
  display: none;
  align-items: flex-start;
  padding-left: 40px;
  border-radius: var(--dl-radius-radius-radius8);
  padding-right: 40px;
  flex-direction: column;
  padding-bottom: var(--dl-space-space-p20);
  background-color: var(--dl-color-gray-white);
}
.home-container037 {
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.home-container038 {
  flex: 0 0 auto;
  width: 116px;
  height: 116px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  background-size: cover;
  background-image: url("/group%201000005158%20(2");
}
.home-container039 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: flex-start;
}
.home-container040 {
  flex: 0 0 auto;
  width: 100%;
  height: 1px;
  display: flex;
  align-items: flex-start;
  background-color: #DEDEDE;
}
.home-container041 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: flex-start;
}
.home-text061 {
  width: 100%;
}
.home-container042 {
  gap: 40px;
  width: 100%;
  height: 300px;
  display: flex;
  align-items: flex-start;
}
.home-container043 {
  gap: 20px;
  flex: 1;
  height: 100%;
  display: none;
  align-items: flex-start;
  padding-left: 40px;
  border-radius: var(--dl-radius-radius-radius8);
  padding-right: 40px;
  flex-direction: column;
  padding-bottom: var(--dl-space-space-p20);
  background-color: var(--dl-color-gray-white);
}
.home-container044 {
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.home-container045 {
  fill: var(--dl-color-gray-white);
  flex: 0 0 auto;
  width: 70px;
  height: 70px;
  display: none;
  overflow: hidden;
  align-items: center;
  border-radius: var(--dl-radius-radius-round);
  flex-direction: column;
  justify-content: center;
  background-color: var(--dl-color-primary-blue);
}
.home-icon02 {
  width: 60%;
  height: 60%;
}
.home-container046 {
  fill: var(--dl-color-gray-white);
  flex: 0 0 auto;
  width: 70px;
  height: 70px;
  display: flex;
  overflow: hidden;
  align-items: center;
  border-radius: var(--dl-radius-radius-round);
  flex-direction: column;
  justify-content: center;
  background-color: rgba(0, 102, 252, 0.08);
}
.home-lottie-node4 {
  width: 60%;
  height: 60%;
}
.home-text065 {
  font-size: 70px;
}
.home-container047 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: flex-start;
}
.home-container048 {
  flex: 0 0 auto;
  width: 100%;
  height: 1px;
  display: flex;
  align-items: flex-start;
  background-color: #DEDEDE;
}
.home-container049 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: flex-start;
}
.home-text070 {
  width: 100%;
}
.home-container050 {
  gap: 20px;
  flex: 1;
  height: 100%;
  display: flex;
  align-items: flex-start;
  padding-left: 40px;
  border-radius: var(--dl-radius-radius-radius8);
  padding-right: 40px;
  flex-direction: column;
  padding-bottom: var(--dl-space-space-p20);
  justify-content: center;
}
.home-container051 {
  width: 100%;
  height: 100px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
.home-lottie-node5 {
  width: 84px;
  height: 89px;
}
.home-text078 {
  width: 100%;
}
.home-container052 {
  gap: 20px;
  flex: 1;
  height: 100%;
  display: flex;
  align-items: flex-start;
  padding-left: 40px;
  border-radius: var(--dl-radius-radius-radius8);
  padding-right: 40px;
  flex-direction: column;
  padding-bottom: var(--dl-space-space-p20);
  justify-content: center;
}
.home-container053 {
  width: 100%;
  height: 100px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
.home-lottie-node6 {
  width: 85px;
  height: 85px;
}
.home-text083 {
  width: 100%;
}
.home-container054 {
  gap: 20px;
  flex: 1;
  height: 100%;
  display: flex;
  align-items: flex-start;
  padding-left: 40px;
  border-radius: var(--dl-radius-radius-radius8);
  padding-right: 40px;
  flex-direction: column;
  padding-bottom: var(--dl-space-space-p20);
  justify-content: center;
}
.home-container055 {
  width: 100%;
  height: 100px;
  display: flex;
  align-items: flex-start;
  padding-left: 17px;
  flex-direction: column;
  justify-content: center;
}
.home-container056 {
  width: 50px;
  height: 50px;
  display: flex;
  overflow: hidden;
  align-items: center;
  border-radius: var(--dl-radius-radius-round);
  flex-direction: column;
  justify-content: center;
}
.home-lottie-node7 {
  width: 100%;
  height: 100%;
}
.home-text089 {
  width: 100%;
}
.home-container057 {
  gap: 20px;
  flex: 1;
  height: 100%;
  display: none;
  align-items: flex-start;
  padding-left: 40px;
  border-radius: var(--dl-radius-radius-radius8);
  padding-right: 40px;
  flex-direction: column;
  padding-bottom: var(--dl-space-space-p20);
  background-color: var(--dl-color-gray-white);
}
.home-container058 {
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.home-container059 {
  flex: 0 0 auto;
  width: 116px;
  height: 116px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  background-size: cover;
  background-image: url("/group%201000005158%20(1");
}
.home-container060 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: flex-start;
}
.home-container061 {
  flex: 0 0 auto;
  width: 100%;
  height: 1px;
  display: flex;
  align-items: flex-start;
  background-color: #DEDEDE;
}
.home-container062 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: flex-start;
}
.home-text095 {
  width: 100%;
}
.home-container063 {
  gap: 20px;
  flex: 1;
  height: 100%;
  display: none;
  align-items: flex-start;
  padding-left: 40px;
  border-radius: var(--dl-radius-radius-radius8);
  padding-right: 40px;
  flex-direction: column;
  padding-bottom: var(--dl-space-space-p20);
  background-color: var(--dl-color-gray-white);
}
.home-container064 {
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.home-container065 {
  flex: 0 0 auto;
  width: 116px;
  height: 116px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  background-size: cover;
  background-image: url("/group%201000005158%20(2");
}
.home-container066 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: flex-start;
}
.home-container067 {
  flex: 0 0 auto;
  width: 100%;
  height: 1px;
  display: flex;
  align-items: flex-start;
  background-color: #DEDEDE;
}
.home-container068 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: flex-start;
}
.home-text104 {
  width: 100%;
}
.home-container069 {
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-p60);
  padding-bottom: var(--dl-space-space-p60);
  justify-content: center;
}
.home-container070 {
  flex: 0 0 auto;
  width: 80%;
  height: auto;
  display: flex;
  align-items: flex-start;
}
.home-container071 {
  flex: 1;
  width: 200px;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.home-image01 {
  width: 565px;
  height: 465px;
  object-fit: cover;
}
.home-container072 {
  gap: 40px;
  flex: 1;
  width: 200px;
  height: auto;
  display: flex;
  align-items: flex-start;
  padding-left: 40px;
  flex-direction: column;
}
.home-container073 {
  gap: 10px;
  width: 100%;
  height: auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-text108 {
  color: rgb(246, 88, 20);
}
.home-container074 {
  gap: 20px;
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-container075 {
  gap: 20px;
  width: 100%;
  height: auto;
  display: flex;
  align-items: flex-start;
}
.home-container076 {
  flex: 0 0 auto;
  width: auto;
  height: 100%;
  display: flex;
  align-items: flex-start;
  padding-top: 3px;
  flex-direction: column;
}
.home-container077 {
  fill: var(--dl-color-gray-white);
  flex: 0 0 auto;
  width: 18px;
  height: 18px;
  display: flex;
  padding: 2px;
  align-items: center;
  border-radius: var(--dl-radius-radius-radius4);
  flex-direction: column;
  justify-content: center;
  background-color: var(--dl-color-primary-lila);
}
.home-icon04 {
  width: 100%;
  height: 100%;
}
.home-container078 {
  gap: 5px;
  flex: 0 0 auto;
  width: 553px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-container079 {
  gap: 20px;
  width: 100%;
  height: auto;
  display: flex;
  align-items: flex-start;
}
.home-container080 {
  flex: 0 0 auto;
  width: auto;
  height: 100%;
  display: flex;
  align-items: flex-start;
  padding-top: 3px;
  flex-direction: column;
}
.home-container081 {
  fill: var(--dl-color-gray-white);
  flex: 0 0 auto;
  width: 18px;
  height: 18px;
  display: flex;
  padding: 2px;
  align-items: center;
  border-radius: var(--dl-radius-radius-radius4);
  flex-direction: column;
  justify-content: center;
  background-color: var(--dl-color-primary-lila);
}
.home-icon06 {
  width: 100%;
  height: 100%;
}
.home-container082 {
  gap: 5px;
  flex: 0 0 auto;
  width: 553px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-navlink {
  display: contents;
}
.home-button-blue {
  text-decoration: none;
}
.home-icon08 {
  width: 18px;
  height: 18px;
}
.home-container083 {
  width: 100%;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-p60);
  padding-bottom: var(--dl-space-space-p60);
  justify-content: center;
}
.home-container084 {
  flex: 0 0 auto;
  width: 80%;
  height: auto;
  display: flex;
  align-items: flex-start;
}
.home-container085 {
  gap: 40px;
  flex: 1;
  width: 200px;
  height: auto;
  display: flex;
  align-items: flex-start;
  padding-left: 40px;
  flex-direction: column;
}
.home-container086 {
  gap: 10px;
  width: 100%;
  height: auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-text118 {
  color: rgb(246, 88, 20);
}
.home-container087 {
  gap: 20px;
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-container088 {
  gap: 20px;
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
}
.home-container089 {
  fill: var(--dl-color-gray-white);
  flex: 0 0 auto;
  width: 18px;
  height: 18px;
  display: flex;
  padding: 2px;
  align-items: center;
  border-radius: var(--dl-radius-radius-radius4);
  flex-direction: column;
  justify-content: center;
  background-color: var(--dl-color-primary-lila);
}
.home-icon10 {
  width: 100%;
  height: 100%;
}
.home-container090 {
  gap: 20px;
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
}
.home-container091 {
  fill: var(--dl-color-gray-white);
  flex: 0 0 auto;
  width: 18px;
  height: 18px;
  display: flex;
  padding: 2px;
  align-items: center;
  border-radius: var(--dl-radius-radius-radius4);
  flex-direction: column;
  justify-content: center;
  background-color: var(--dl-color-primary-lila);
}
.home-icon12 {
  width: 100%;
  height: 100%;
}
.home-container092 {
  gap: 20px;
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
}
.home-container093 {
  fill: var(--dl-color-gray-white);
  flex: 0 0 auto;
  width: 18px;
  height: 18px;
  display: flex;
  padding: 2px;
  align-items: center;
  border-radius: var(--dl-radius-radius-radius4);
  flex-direction: column;
  justify-content: center;
  background-color: var(--dl-color-primary-lila);
}
.home-icon14 {
  width: 100%;
  height: 100%;
}
.home-container094 {
  gap: 20px;
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
}
.home-container095 {
  fill: var(--dl-color-gray-white);
  flex: 0 0 auto;
  width: 18px;
  height: 18px;
  display: flex;
  padding: 2px;
  align-items: center;
  border-radius: var(--dl-radius-radius-radius4);
  flex-direction: column;
  justify-content: center;
  background-color: var(--dl-color-primary-lila);
}
.home-icon16 {
  width: 100%;
  height: 100%;
}
.home-navlink1 {
  display: contents;
}
.home-button-blue1 {
  text-decoration: none;
}
.home-icon18 {
  width: 18px;
  height: 18px;
}
.home-container096 {
  flex: 1;
  width: 200px;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.home-image02 {
  width: 656px;
  height: 465px;
  object-fit: cover;
}
.home-container097 {
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-p60);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-p60);
  justify-content: center;
}
.home-container098 {
  color: var(--dl-color-gray-white);
  width: 80%;
  height: 326px;
  display: flex;
  overflow: hidden;
  align-items: flex-start;
  border-radius: 30px;
  flex-direction: column;
  background-size: cover;
  background-image: url("/frame%20309-1500w.png");
  background-position: center;
}
.home-container099 {
  gap: 30px;
  width: 100%;
  height: 194px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.home-text135 {
  opacity: 0.8;
}
.home-container100 {
  gap: 40px;
  flex: 1;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}
.home-container101 {
  gap: 40px;
  width: 80%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}
.home-container102 {
  gap: 10px;
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-container103 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: center;
}
.home-container104 {
  gap: 20px;
  display: flex;
  align-items: center;
}
.home-image03 {
  width: 24px;
  height: 32px;
  object-fit: cover;
}
.home-text136 {
  font-size: 48px;
  font-style: normal;
  font-family: Poppins;
  font-weight: 600;
  line-height: 51.84px;
}
.home-container105 {
  flex: 0 0 auto;
  width: 1px;
  height: 100px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  background-color: var(--dl-color-gray-white);
}
.home-container106 {
  gap: 10px;
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-container107 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: center;
}
.home-container108 {
  gap: 20px;
  display: flex;
  align-items: center;
}
.home-image04 {
  width: 24px;
  height: 32px;
  object-fit: cover;
}
.home-text138 {
  font-size: 48px;
  font-style: normal;
  font-family: Poppins;
  font-weight: 600;
  line-height: 51.84px;
}
.home-container109 {
  flex: 0 0 auto;
  width: 1px;
  height: 100px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  background-color: var(--dl-color-gray-white);
}
.home-container110 {
  gap: 10px;
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-container111 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: center;
}
.home-container112 {
  gap: 20px;
  display: flex;
  align-items: center;
}
.home-image05 {
  width: 24px;
  height: 32px;
  object-fit: cover;
}
.home-text140 {
  font-size: 48px;
  font-style: normal;
  font-family: Poppins;
  font-weight: 600;
  line-height: 51.84px;
}
.home-container113 {
  flex: 0 0 auto;
  width: 1px;
  height: 100px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  background-color: var(--dl-color-gray-white);
}
.home-container114 {
  gap: 10px;
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-container115 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: center;
}
.home-container116 {
  gap: 20px;
  display: flex;
  align-items: center;
}
.home-image06 {
  width: 24px;
  height: 32px;
  object-fit: cover;
}
.home-text142 {
  font-size: 48px;
  font-style: normal;
  font-family: Poppins;
  font-weight: 600;
  line-height: 51.84px;
}
.home-container117 {
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-p60);
  padding-bottom: var(--dl-space-space-p60);
  justify-content: center;
}
.home-container118 {
  gap: 40px;
  flex: 0 0 auto;
  width: 80%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-container119 {
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  flex-direction: row;
}
.home-container120 {
  flex: 1;
  width: 200px;
  height: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-container121 {
  gap: 10px;
  width: 100%;
  height: auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-text144 {
  color: rgb(246, 88, 20);
}
.home-container122 {
  flex: 1;
  width: 200px;
  height: 100%;
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  justify-content: center;
}
.home-navlink2 {
  display: contents;
}
.home-button-blue2 {
  text-decoration: none;
}
.home-icon20 {
  width: 18px;
  height: 18px;
}
.home-container123 {
  width: 100%;
  height: auto;
  display: flex;
  align-items: flex-start;
}
.home-container124 {
  flex: 1;
  height: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
.home-image07 {
  width: 656px;
  height: 465px;
  object-fit: cover;
}
.home-container125 {
  gap: 20px;
  flex: 1;
  height: 100%;
  display: flex;
  align-items: flex-end;
  flex-direction: column;
}
.home-text150 {
  width: 100%;
}
.home-thq-dropdown {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.home-dropdown-list {
  left: 0%;
  width: max-content;
  display: none;
  z-index: 100;
  position: absolute;
  min-width: 100%;
  transition: 0.3s;
  align-items: stretch;
  border-color: #D9D9D9;
  border-width: 1px;
  border-radius: var(--dl-radius-radius-radius4);
  flex-direction: column;
  list-style-type: none;
  background-color: var(--dl-color-gray-white);
  list-style-position: inside;
}
.home-dropdown {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.home-dropdown-toggle {
  fill: #595959;
  color: #595959;
  width: 100%;
  display: inline-flex;
  transition: 0.3s;
  align-items: center;
  padding-top: var(--dl-space-space-halfunit);
  padding-left: var(--dl-space-space-unit);
  border-radius: var(--dl-radius-radius-radius4);
  padding-right: var(--dl-space-space-unit);
  padding-bottom: var(--dl-space-space-halfunit);
}
.home-dropdown-toggle:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.home-text155 {
  width: 100%;
  cursor: pointer;
  display: flex;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
}
.home-dropdown1 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.home-dropdown-toggle1 {
  fill: #595959;
  color: #595959;
  width: 100%;
  display: inline-flex;
  transition: 0.3s;
  align-items: center;
  padding-top: var(--dl-space-space-halfunit);
  padding-left: var(--dl-space-space-unit);
  border-radius: var(--dl-radius-radius-radius4);
  padding-right: var(--dl-space-space-unit);
  padding-bottom: var(--dl-space-space-halfunit);
}
.home-dropdown-toggle1:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.home-text156 {
  width: 100%;
  cursor: pointer;
  display: flex;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
}
.home-dropdown2 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.home-dropdown-toggle2 {
  fill: #595959;
  color: #595959;
  width: 100%;
  display: inline-flex;
  transition: 0.3s;
  align-items: center;
  padding-top: var(--dl-space-space-halfunit);
  padding-left: var(--dl-space-space-unit);
  border-radius: var(--dl-radius-radius-radius4);
  padding-right: var(--dl-space-space-unit);
  padding-bottom: var(--dl-space-space-halfunit);
}
.home-dropdown-toggle2:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.home-text157 {
  width: 100%;
  cursor: pointer;
  display: flex;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
}
.home-container126 {
  width: 100%;
  height: auto;
  display: none;
  align-items: center;
  padding-top: var(--dl-space-space-p60);
  padding-bottom: var(--dl-space-space-p40);
  justify-content: center;
  background-image: linear-gradient(0deg, rgba(189, 195, 199, 0) 0.00%,rgb(232, 240, 255) 100.00%);
}
.home-container127 {
  gap: 40px;
  flex: 0 0 auto;
  width: 80%;
  height: 798px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-container128 {
  width: 100%;
  height: auto;
  display: flex;
  align-items: flex-end;
  flex-direction: row;
}
.home-container129 {
  flex: 1;
  width: 200px;
  height: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-container130 {
  gap: 10px;
  width: 100%;
  height: auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-text158 {
  color: rgb(246, 88, 20);
}
.home-container131 {
  flex: 1;
  width: 200px;
  height: 100%;
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  justify-content: flex-end;
}
.home-container132 {
  gap: 5px;
  fill: var(--dl-color-gray-white);
  flex: 0 0 auto;
  color: var(--dl-color-gray-white);
  width: 177px;
  height: 45px;
  display: flex;
  align-items: center;
  border-radius: 30px;
  justify-content: center;
  background-color: #0066FC;
}
.home-text160 {
  font-size: 18px;
}
.home-icon22 {
  width: 24px;
  height: 24px;
}
.home-container133 {
  width: 100%;
  height: 535px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}
.home-container134 {
  flex: 0 0 auto;
  width: 475px;
  height: 100%;
  display: flex;
  overflow: hidden;
  align-items: flex-start;
  border-radius: 20px;
  flex-direction: column;
  background-color: var(--dl-color-gray-white);
}
.home-container135 {
  width: 100%;
  height: 305px;
  display: flex;
  align-items: flex-start;
  background-size: cover;
  background-image: url("/image-400h.png");
  background-repeat: no-repeat;
  background-position: bottom;
}
.home-container136 {
  flex: 1;
  width: 100%;
  display: flex;
  align-items: flex-start;
  padding-left: var(--dl-space-space-p20);
  padding-right: var(--dl-space-space-p20);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-p20);
  justify-content: space-between;
}
.home-container137 {
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-container138 {
  width: 100%;
  height: 70px;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.home-text161 {
  opacity: 0.7;
  line-height: 19.36px;
}
.home-text162 {
  opacity: 0.7;
  line-height: 19.36px;
}
.home-container139 {
  flex: 0 0 auto;
  width: 100%;
  height: 1px;
  display: flex;
  align-items: flex-start;
  background-color: #DEDEDE;
}
.home-container140 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-text163 {
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 36px;
}
.home-container141 {
  gap: 5px;
  display: flex;
  align-items: center;
  flex-direction: row;
}
.home-text164 {
  font-size: 18px;
  font-style: normal;
  font-family: Poppins;
  font-weight: 600;
  line-height: 27px;
}
.home-icon24 {
  width: 24px;
  height: 24px;
}
.home-container142 {
  flex: 0 0 auto;
  width: 475px;
  height: 100%;
  display: flex;
  overflow: hidden;
  align-items: flex-start;
  border-radius: 20px;
  flex-direction: column;
  background-color: var(--dl-color-gray-white);
}
.home-container143 {
  width: 100%;
  height: 305px;
  display: flex;
  align-items: flex-start;
  background-size: cover;
  background-image: url("/image%20(1");
  background-repeat: no-repeat;
  background-position: bottom;
}
.home-container144 {
  flex: 1;
  width: 100%;
  display: flex;
  align-items: flex-start;
  padding-left: var(--dl-space-space-p20);
  padding-right: var(--dl-space-space-p20);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-p20);
  justify-content: space-between;
}
.home-container145 {
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-container146 {
  width: 100%;
  height: 70px;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.home-text165 {
  opacity: 0.7;
  line-height: 19.36px;
}
.home-text166 {
  opacity: 0.7;
  line-height: 19.36px;
}
.home-container147 {
  flex: 0 0 auto;
  width: 100%;
  height: 1px;
  display: flex;
  align-items: flex-start;
  background-color: #DEDEDE;
}
.home-container148 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-text167 {
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 36px;
}
.home-container149 {
  gap: 5px;
  display: flex;
  align-items: center;
  flex-direction: row;
}
.home-text168 {
  font-size: 18px;
  font-style: normal;
  font-family: Poppins;
  font-weight: 600;
  line-height: 27px;
}
.home-icon26 {
  width: 24px;
  height: 24px;
}
.home-container150 {
  flex: 0 0 auto;
  width: 475px;
  height: 100%;
  display: flex;
  overflow: hidden;
  align-items: flex-start;
  border-radius: 20px;
  flex-direction: column;
  background-color: var(--dl-color-gray-white);
}
.home-container151 {
  width: 100%;
  height: 305px;
  display: flex;
  align-items: flex-start;
  background-size: cover;
  background-image: url("/image%20(2");
  background-repeat: no-repeat;
  background-position: bottom;
}
.home-container152 {
  flex: 1;
  width: 100%;
  display: flex;
  align-items: flex-start;
  padding-left: var(--dl-space-space-p20);
  padding-right: var(--dl-space-space-p20);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-p20);
  justify-content: space-between;
}
.home-container153 {
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-container154 {
  width: 100%;
  height: 70px;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.home-text169 {
  opacity: 0.7;
  line-height: 19.36px;
}
.home-text170 {
  opacity: 0.7;
  line-height: 19.36px;
}
.home-container155 {
  flex: 0 0 auto;
  width: 100%;
  height: 1px;
  display: flex;
  align-items: flex-start;
  background-color: #DEDEDE;
}
.home-container156 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-text171 {
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 36px;
}
.home-container157 {
  gap: 5px;
  display: flex;
  align-items: center;
  flex-direction: row;
}
.home-text172 {
  font-size: 18px;
  font-style: normal;
  font-family: Poppins;
  font-weight: 600;
  line-height: 27px;
}
.home-icon28 {
  width: 24px;
  height: 24px;
}
.home-container158 {
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-p40);
  padding-bottom: var(--dl-space-space-p40);
  background-size: cover;
  justify-content: center;
  background-image: url("/rectangle%20166-1500w.png");
}
.home-container159 {
  flex: 0 0 auto;
  width: 80%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.home-container160 {
  gap: 20px;
  flex: 1;
  color: var(--dl-color-gray-white);
  height: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
.home-text174 {
  opacity: 0.8;
}
.home-container161 {
  gap: 10px;
  width: 526px;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.home-container162 {
  gap: 5px;
  flex: 0 0 auto;
  color: var(--dl-color-gray-white);
  width: 100%;
  height: 45px;
  display: flex;
  align-items: center;
  padding-left: var(--dl-space-space-p20);
  border-radius: 35px;
  flex-direction: row;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.23);
}
.home-textinput {
  width: 100%;
  height: 100%;
  background-color: transparent;
}
.home-container163 {
  gap: 10px;
  flex: 0 0 auto;
  width: 100%;
  height: 45px;
  display: flex;
  align-items: center;
  border-radius: 35px;
  flex-direction: row;
  justify-content: center;
  background-color: var(--dl-color-gray-white);
}
.home-text178 {
  font-size: 18px;
  line-height: 22px;
}
.home-icon30 {
  width: 24px;
  height: 24px;
}
.home-mobil {
  flex: 0 0 auto;
  width: 200px;
  height: 100px;
  display: none;
  align-items: flex-start;
  flex-direction: column;
}
.home-top {
  width: 100%;
  display: flex;
  position: relative;
}
.home-container164 {
  display: flex;
}
.home-container165 {
  display: flex;
  position: absolute;
}
.home-container166 {
  width: 100%;
  display: flex;
}
.home-container167 {
  gap: 20px;
  width: 100%;
  height: auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-container168 {
  color: var(--dl-color-gray-text);
  display: flex;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
  align-items: flex-start;
  padding-top: 5px;
  padding-left: 15px;
  border-radius: 20px;
  padding-right: 15px;
  flex-direction: column;
  padding-bottom: 5px;
  background-image: linear-gradient(315deg, rgba(255, 255, 255, 0.5) 0.00%,rgb(255, 255, 255) 99.00%);
}
.home-text179 {
  font-style: normal;
  font-weight: 600;
}
.home-container169 {
  gap: 20px;
  color: white;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-text180 {
  text-shadow: 2px 2px rgba(0, 0, 0, 0.2);
}
.home-content {
  flex: 0 0 auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-container170 {
  flex: 0 0 auto;
  width: 100%;
  height: 20px;
  display: flex;
  box-shadow: 0px -5px 10px 0px rgba(66, 66, 66, 0.3);
  align-items: flex-start;
  border-color: var(--dl-color-gray-border);
  border-width: 1px;
  background-color: rgba(243, 243, 243, 0.63);
  border-left-width: 0px;
  border-right-width: 0px;
}
.home-container171 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-container172 {
  flex: 0 0 auto;
  width: 200px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-text182 {
  text-align: center;
}
.home-text186 {
  text-align: center;
}
.home-container173 {
  flex: 0 0 auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-container174 {
  gap: 10px;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.home-text190 {
  color: rgb(246, 88, 20);
}
.home-text191 {
  text-align: center;
}
.home-text192 {
  text-align: center;
}
.home-image08 {
  width: 100%;
  object-fit: cover;
}
.home-container175 {
  gap: 20px;
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-container176 {
  gap: 20px;
  width: 100%;
  height: auto;
  display: flex;
  align-items: flex-start;
}
.home-container177 {
  flex: 0 0 auto;
  width: auto;
  height: 100%;
  display: flex;
  align-items: flex-start;
  padding-top: 3px;
  flex-direction: column;
}
.home-container178 {
  fill: var(--dl-color-gray-white);
  flex: 0 0 auto;
  width: 18px;
  height: 18px;
  display: flex;
  padding: 2px;
  align-items: center;
  border-radius: var(--dl-radius-radius-radius4);
  flex-direction: column;
  justify-content: center;
  background-color: var(--dl-color-primary-lila);
}
.home-icon32 {
  width: 100%;
  height: 100%;
}
.home-container179 {
  gap: 5px;
  flex: 0 0 auto;
  width: 553px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-container180 {
  gap: 20px;
  width: 100%;
  height: auto;
  display: flex;
  align-items: flex-start;
}
.home-container181 {
  flex: 0 0 auto;
  width: auto;
  height: 100%;
  display: flex;
  align-items: flex-start;
  padding-top: 3px;
  flex-direction: column;
}
.home-container182 {
  fill: var(--dl-color-gray-white);
  flex: 0 0 auto;
  width: 18px;
  height: 18px;
  display: flex;
  padding: 2px;
  align-items: center;
  border-radius: var(--dl-radius-radius-radius4);
  flex-direction: column;
  justify-content: center;
  background-color: var(--dl-color-primary-lila);
}
.home-icon34 {
  width: 100%;
  height: 100%;
}
.home-container183 {
  gap: 5px;
  flex: 0 0 auto;
  width: 553px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-container184 {
  flex: 0 0 auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-container185 {
  gap: 10px;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.home-text197 {
  color: rgb(246, 88, 20);
}
.home-text198 {
  text-align: center;
}
.home-text199 {
  text-align: center;
}
.home-image09 {
  width: 100%;
  height: auto;
  object-fit: cover;
}
.home-container186 {
  gap: 20px;
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-container187 {
  gap: 20px;
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
}
.home-container188 {
  fill: var(--dl-color-gray-white);
  flex: 0 0 auto;
  width: 18px;
  height: 18px;
  display: flex;
  padding: 2px;
  align-items: center;
  border-radius: var(--dl-radius-radius-radius4);
  flex-direction: column;
  justify-content: center;
  background-color: var(--dl-color-primary-lila);
}
.home-icon36 {
  width: 100%;
  height: 100%;
}
.home-container189 {
  gap: 20px;
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
}
.home-container190 {
  fill: var(--dl-color-gray-white);
  flex: 0 0 auto;
  width: 18px;
  height: 18px;
  display: flex;
  padding: 2px;
  align-items: center;
  border-radius: var(--dl-radius-radius-radius4);
  flex-direction: column;
  justify-content: center;
  background-color: var(--dl-color-primary-lila);
}
.home-icon38 {
  width: 100%;
  height: 100%;
}
.home-container191 {
  gap: 20px;
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
}
.home-container192 {
  fill: var(--dl-color-gray-white);
  flex: 0 0 auto;
  width: 18px;
  height: 18px;
  display: flex;
  padding: 2px;
  align-items: center;
  border-radius: var(--dl-radius-radius-radius4);
  flex-direction: column;
  justify-content: center;
  background-color: var(--dl-color-primary-lila);
}
.home-icon40 {
  width: 100%;
  height: 100%;
}
.home-container193 {
  gap: 20px;
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
}
.home-container194 {
  fill: var(--dl-color-gray-white);
  flex: 0 0 auto;
  width: 18px;
  height: 18px;
  display: flex;
  padding: 2px;
  align-items: center;
  border-radius: var(--dl-radius-radius-radius4);
  flex-direction: column;
  justify-content: center;
  background-color: var(--dl-color-primary-lila);
}
.home-icon42 {
  width: 100%;
  height: 100%;
}
.home-container195 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: flex-start;
}
.home-container196 {
  fill: var(--dl-color-gray-white);
  flex: 0 0 auto;
  color: white;
  display: flex;
  overflow: hidden;
  align-items: flex-start;
  flex-direction: column;
}
.home-container197 {
  gap: 10px;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.home-text204 {
  text-align: center;
}
.home-text205 {
  text-align: center;
}
.home-container198 {
  flex: 0 0 auto;
  width: 200px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-container199 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: flex-start;
}
.home-container200 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-text206 {
  font-size: 48px;
  font-style: normal;
  font-family: Poppins;
  font-weight: 600;
  line-height: 40px;
}
.home-container201 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-text208 {
  font-size: 48px;
  font-style: normal;
  font-family: Poppins;
  font-weight: 600;
  line-height: 40px;
}
.home-container202 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: flex-start;
}
.home-container203 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-text210 {
  font-size: 48px;
  font-style: normal;
  font-family: Poppins;
  font-weight: 600;
  line-height: 40px;
}
.home-container204 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-text212 {
  font-size: 48px;
  font-style: normal;
  font-family: Poppins;
  font-weight: 600;
  line-height: 40px;
}
.home-container205 {
  flex: 0 0 auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-container206 {
  gap: 10px;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.home-text214 {
  color: rgb(246, 88, 20);
}
.home-text215 {
  text-align: center;
}
.home-text216 {
  text-align: center;
}
.home-image10 {
  width: 656px;
  height: 465px;
  object-fit: cover;
}
@media(max-width: 1440px) {
  .home-container001 {
    height: 548px;
  }
  .home-container004 {
    width: 564px;
  }
  .home-text001 {
    font-size: 30px;
    line-height: 45px;
  }
  .home-image {
    width: 470px;
    height: 471px;
  }
  .home-container134 {
    width: 350px;
  }
  .home-container142 {
    width: 350px;
  }
  .home-container150 {
    width: 350px;
  }
}
@media(max-width: 479px) {
  .home-web {
    display: none;
  }
  .home-mobil {
    flex: 1;
    width: 100%;
    display: flex;
  }
  .home-top {
    height: auto;
    background-image: url("/bg/img_9.svg");
    background-repeat: no-repeat;
    background-position: top bottom;
  }
  .home-container164 {
    height: auto;
    align-items: center;
    flex-direction: column;
  }
  .home-container165 {
    width: 100%;
  }
  .home-container166 {
    margin-top: 70px;
    padding-top: var(--dl-space-space-p40);
    padding-left: var(--dl-space-space-p30);
    padding-right: var(--dl-space-space-p30);
    padding-bottom: var(--dl-space-space-p40);
  }
  .home-container167 {
    align-items: center;
  }
  .home-text180 {
    font-size: 25px;
    text-align: center;
  }
  .home-text181 {
    color: rgba(255, 255, 255, 0.7);
    font-style: normal;
    font-weight: 500;
  }
  .home-content {
    width: 100%;
    padding-bottom: 0px;
  }
  .home-container171 {
    gap: 40px;
    width: 100%;
    align-items: center;
    padding-top: var(--dl-space-space-p40);
    padding-left: var(--dl-space-space-p30);
    padding-right: var(--dl-space-space-p30);
    padding-bottom: var(--dl-space-space-p40);
  }
  .home-container172 {
    gap: 20px;
    width: 100%;
  }
  .home-container173 {
    width: 100%;
    align-items: center;
    padding-top: var(--dl-space-space-p40);
    padding-left: var(--dl-space-space-p30);
    padding-right: var(--dl-space-space-p30);
    padding-bottom: var(--dl-space-space-p40);
  }
  .home-container179 {
    flex: 1;
  }
  .home-container183 {
    flex: 1;
  }
  .home-container184 {
    width: 100%;
    padding-top: var(--dl-space-space-p40);
    padding-left: var(--dl-space-space-p30);
    padding-right: var(--dl-space-space-p30);
    padding-bottom: var(--dl-space-space-p40);
  }
  .home-container195 {
    padding-top: var(--dl-space-space-p40);
    padding-left: var(--dl-space-space-p30);
    padding-right: var(--dl-space-space-p30);
    padding-bottom: var(--dl-space-space-p40);
  }
  .home-container196 {
    gap: 20px;
    flex: 1;
    padding: var(--dl-space-space-p20);
    align-items: center;
    border-radius: 30px;
    background-size: cover;
    background-image: url("/frame%20309-1500w.png");
  }
  .home-container197 {
    padding-bottom: var(--dl-space-space-p20);
  }
  .home-container198 {
    gap: 20px;
    width: 100%;
    align-items: center;
  }
  .home-container199 {
    gap: 20px;
  }
  .home-container200 {
    flex: 1;
    align-items: center;
    padding-top: 10px;
    border-radius: var(--dl-radius-radius-radius8);
    padding-bottom: 10px;
    background-color: rgba(255, 255, 255, 0.2);
  }
  .home-text206 {
    font-size: 25px;
  }
  .home-text207 {
    font-size: 12px;
  }
  .home-container201 {
    flex: 1;
    align-items: center;
    padding-top: 10px;
    border-radius: var(--dl-radius-radius-radius8);
    padding-bottom: 10px;
    background-color: rgba(255, 255, 255, 0.2);
  }
  .home-text208 {
    font-size: 25px;
  }
  .home-text209 {
    font-size: 12px;
  }
  .home-container202 {
    gap: 20px;
  }
  .home-container203 {
    flex: 1;
    align-items: center;
    padding-top: 10px;
    border-radius: var(--dl-radius-radius-radius8);
    padding-bottom: 10px;
    background-color: rgba(255, 255, 255, 0.2);
  }
  .home-text210 {
    font-size: 25px;
    font-style: normal;
    font-family: Poppins;
    font-weight: 600;
    line-height: 40px;
  }
  .home-text211 {
    font-size: 12px;
  }
  .home-container204 {
    flex: 1;
    align-items: center;
    padding-top: 10px;
    border-radius: var(--dl-radius-radius-radius8);
    padding-bottom: 10px;
    background-color: rgba(255, 255, 255, 0.2);
  }
  .home-text212 {
    font-size: 25px;
    font-style: normal;
    font-family: Poppins;
    font-weight: 600;
    line-height: 40px;
  }
  .home-text213 {
    font-size: 12px;
  }
  .home-container205 {
    width: 100%;
    padding-top: var(--dl-space-space-p40);
    padding-left: var(--dl-space-space-p30);
    padding-right: var(--dl-space-space-p30);
    padding-bottom: var(--dl-space-space-p40);
  }
  .home-container206 {
    width: 100%;
  }
  .home-text214 {
    color: rgb(246, 88, 20);
  }
  .home-text215 {
    font-size: 20px;
    line-height: 30px;
  }
  .home-image10 {
    width: 100%;
    height: auto;
  }
}
