.home-container-1-component-home-container-1-component {
  width: 100%;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-direction: column;
}
.home-container-1-component-container {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  overflow: hidden;
  align-items: flex-start;
}
.home-container-1-component-container01 {
  display: flex;
  align-items: flex-start;
}
.home-container-1-component-container02 {
  display: flex;
  position: relative;
  align-items: flex-start;
}
.home-container-1-component-icon {
  fill: var(--dl-color-primary-lila);
  width: 24px;
  height: 24px;
}
.home-container-1-component-container03 {
  display: flex;
  align-items: flex-start;
}
.home-container-1-component-container04 {
  width: 100%;
  display: flex;
  align-items: flex-start;
}
.home-container-1-component-text01 {
  width: 100%;
}
.home-container-1-component-container05 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  overflow: hidden;
  align-items: flex-start;
}
.home-container-1-component-container06 {
  display: flex;
  align-items: flex-start;
}
.home-container-1-component-container07 {
  display: flex;
  position: relative;
  align-items: flex-start;
}
.home-container-1-component-icon02 {
  fill: var(--dl-color-primary-lila);
  width: 24px;
  height: 24px;
}
.home-container-1-component-container08 {
  display: flex;
  align-items: flex-start;
}
.home-container-1-component-container09 {
  width: 100%;
  display: flex;
  align-items: flex-start;
}
.home-container-1-component-text03 {
  width: 100%;
}
.home-container-1-component-container10 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  overflow: hidden;
  align-items: flex-start;
}
.home-container-1-component-container11 {
  display: flex;
  align-items: flex-start;
}
.home-container-1-component-container12 {
  display: flex;
  position: relative;
  align-items: flex-start;
}
.home-container-1-component-icon04 {
  fill: var(--dl-color-primary-lila);
  width: 24px;
  height: 24px;
}
.home-container-1-component-container13 {
  display: flex;
  align-items: flex-start;
}
.home-container-1-component-container14 {
  width: 100%;
  display: flex;
  align-items: flex-start;
}
.home-container-1-component-text05 {
  width: 100%;
}
.home-container-1-component-container15 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  overflow: hidden;
  align-items: flex-start;
}
.home-container-1-component-container16 {
  display: flex;
  align-items: flex-start;
}
.home-container-1-component-container17 {
  display: flex;
  position: relative;
  align-items: flex-start;
}
.home-container-1-component-icon06 {
  fill: var(--dl-color-primary-lila);
  width: 24px;
  height: 24px;
}
.home-container-1-component-container18 {
  display: flex;
  align-items: flex-start;
}
.home-container-1-component-container19 {
  width: 100%;
  display: flex;
  align-items: flex-start;
}
.home-container-1-component-text07 {
  width: 100%;
}
.home-container-1-component-container20 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  overflow: hidden;
  align-items: flex-start;
}
.home-container-1-component-container21 {
  display: flex;
  align-items: flex-start;
}
.home-container-1-component-container22 {
  display: flex;
  position: relative;
  align-items: flex-start;
}
.home-container-1-component-icon08 {
  fill: var(--dl-color-primary-lila);
  width: 24px;
  height: 24px;
}
.home-container-1-component-container23 {
  display: flex;
  align-items: flex-start;
}
.home-container-1-component-container24 {
  width: 100%;
  display: flex;
  align-items: flex-start;
}
.home-container-1-component-text09 {
  width: 100%;
}
.home-container-1-component-container25 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  overflow: hidden;
  align-items: flex-start;
}
.home-container-1-component-container26 {
  display: flex;
  align-items: flex-start;
}
.home-container-1-component-container27 {
  display: flex;
  position: relative;
  align-items: flex-start;
}
.home-container-1-component-icon10 {
  fill: var(--dl-color-primary-lila);
  width: 24px;
  height: 24px;
}
.home-container-1-component-container28 {
  display: flex;
  align-items: flex-start;
}
.home-container-1-component-container29 {
  width: 100%;
  display: flex;
  align-items: flex-start;
}
.home-container-1-component-text11 {
  width: 100%;
}
.home-container-1-component-link {
  display: contents;
}
.home-container-1-component-button-blue {
  text-decoration: none;
}
.home-container-1-component-icon12 {
  width: 24px;
  height: 24px;
}
.home-container-1-component-icon14 {
  width: 24px;
  height: 24px;
}
@media(max-width: 479px) {
  .home-container-1-component-home-container-1-component {
    gap: 20px;
  }
  .home-container-1-component-container {
    border-color: var(--dl-color-gray-border-2);
    border-width: 1px;
    border-radius: var(--dl-radius-radius-radius8);
    flex-direction: column;
    background-color: var(--dl-color-gray-white);
  }
  .home-container-1-component-container01 {
    width: 100%;
    height: 80px;
    box-shadow: 0px 3px 5px 0px rgba(212, 212, 212, 0.2);
  }
  .home-container-1-component-container02 {
    width: 68px;
    height: 100%;
    align-items: center;
    justify-content: center;
  }
  .home-container-1-component-icon {
    width: 30px;
    height: 30px;
  }
  .home-container-1-component-container03 {
    flex: 1;
    height: 100%;
    align-items: center;
    padding-right: 20px;
  }
  .home-container-1-component-text {
    width: 100%;
    font-size: 16px;
    line-height: 25px;
  }
  .home-container-1-component-container04 {
    width: 100%;
    padding-top: var(--dl-space-space-p20);
    padding-left: var(--dl-space-space-p30);
    padding-right: var(--dl-space-space-p30);
    padding-bottom: var(--dl-space-space-p20);
    justify-content: center;
    background-color: rgba(217, 217, 217, 0.2);
  }
  .home-container-1-component-text01 {
    text-align: left;
  }
  .home-container-1-component-container05 {
    border-color: var(--dl-color-gray-border-2);
    border-width: 1px;
    border-radius: var(--dl-radius-radius-radius8);
    flex-direction: column;
    background-color: var(--dl-color-gray-white);
  }
  .home-container-1-component-container06 {
    width: 100%;
    height: 80px;
    box-shadow: 0px 3px 5px 0px rgba(212, 212, 212, 0.2);
  }
  .home-container-1-component-container07 {
    width: 68px;
    height: 100%;
    align-items: center;
    justify-content: center;
  }
  .home-container-1-component-icon02 {
    width: 30px;
    height: 30px;
  }
  .home-container-1-component-container08 {
    flex: 1;
    height: 100%;
    align-items: center;
    padding-right: 20px;
  }
  .home-container-1-component-text02 {
    width: 100%;
    font-size: 16px;
    line-height: 25px;
  }
  .home-container-1-component-container09 {
    width: 100%;
    padding-top: var(--dl-space-space-p20);
    padding-left: var(--dl-space-space-p30);
    padding-right: var(--dl-space-space-p30);
    padding-bottom: var(--dl-space-space-p20);
    justify-content: center;
    background-color: rgba(217, 217, 217, 0.2);
  }
  .home-container-1-component-text03 {
    text-align: left;
  }
  .home-container-1-component-container10 {
    border-color: var(--dl-color-gray-border-2);
    border-width: 1px;
    border-radius: var(--dl-radius-radius-radius8);
    flex-direction: column;
    background-color: var(--dl-color-gray-white);
  }
  .home-container-1-component-container11 {
    width: 100%;
    height: 80px;
    box-shadow: 0px 3px 5px 0px rgba(212, 212, 212, 0.2);
  }
  .home-container-1-component-container12 {
    width: 68px;
    height: 100%;
    align-items: center;
    justify-content: center;
  }
  .home-container-1-component-icon04 {
    width: 30px;
    height: 30px;
  }
  .home-container-1-component-container13 {
    flex: 1;
    height: 100%;
    align-items: center;
    padding-right: 20px;
  }
  .home-container-1-component-text04 {
    width: 100%;
    font-size: 16px;
    line-height: 25px;
  }
  .home-container-1-component-container14 {
    width: 100%;
    padding-top: var(--dl-space-space-p20);
    padding-left: var(--dl-space-space-p30);
    padding-right: var(--dl-space-space-p30);
    padding-bottom: var(--dl-space-space-p20);
    justify-content: center;
    background-color: rgba(217, 217, 217, 0.2);
  }
  .home-container-1-component-text05 {
    text-align: left;
  }
  .home-container-1-component-container15 {
    border-color: var(--dl-color-gray-border-2);
    border-width: 1px;
    border-radius: var(--dl-radius-radius-radius8);
    flex-direction: column;
    background-color: var(--dl-color-gray-white);
  }
  .home-container-1-component-container16 {
    width: 100%;
    height: 80px;
    box-shadow: 0px 3px 5px 0px rgba(212, 212, 212, 0.2);
  }
  .home-container-1-component-container17 {
    width: 68px;
    height: 100%;
    align-items: center;
    justify-content: center;
  }
  .home-container-1-component-icon06 {
    width: 30px;
    height: 30px;
  }
  .home-container-1-component-container18 {
    flex: 1;
    height: 100%;
    align-items: center;
    padding-right: 20px;
  }
  .home-container-1-component-text06 {
    width: 100%;
    font-size: 16px;
    line-height: 25px;
  }
  .home-container-1-component-container19 {
    width: 100%;
    padding-top: var(--dl-space-space-p20);
    padding-left: var(--dl-space-space-p30);
    padding-right: var(--dl-space-space-p30);
    padding-bottom: var(--dl-space-space-p20);
    justify-content: center;
    background-color: rgba(217, 217, 217, 0.2);
  }
  .home-container-1-component-text07 {
    text-align: left;
  }
  .home-container-1-component-container20 {
    border-color: var(--dl-color-gray-border-2);
    border-width: 1px;
    border-radius: var(--dl-radius-radius-radius8);
    flex-direction: column;
    background-color: var(--dl-color-gray-white);
  }
  .home-container-1-component-container21 {
    width: 100%;
    height: 80px;
    box-shadow: 0px 3px 5px 0px rgba(212, 212, 212, 0.2);
  }
  .home-container-1-component-container22 {
    width: 68px;
    height: 100%;
    align-items: center;
    justify-content: center;
  }
  .home-container-1-component-icon08 {
    width: 30px;
    height: 30px;
  }
  .home-container-1-component-container23 {
    flex: 1;
    height: 100%;
    align-items: center;
    padding-right: 20px;
  }
  .home-container-1-component-text08 {
    width: 100%;
    font-size: 16px;
    line-height: 25px;
  }
  .home-container-1-component-container24 {
    width: 100%;
    padding-top: var(--dl-space-space-p20);
    padding-left: var(--dl-space-space-p30);
    padding-right: var(--dl-space-space-p30);
    padding-bottom: var(--dl-space-space-p20);
    justify-content: center;
    background-color: rgba(217, 217, 217, 0.2);
  }
  .home-container-1-component-text09 {
    text-align: left;
  }
  .home-container-1-component-container25 {
    border-color: var(--dl-color-gray-border-2);
    border-width: 1px;
    border-radius: var(--dl-radius-radius-radius8);
    flex-direction: column;
    background-color: var(--dl-color-gray-white);
  }
  .home-container-1-component-container26 {
    width: 100%;
    height: 80px;
    box-shadow: 0px 3px 5px 0px rgba(212, 212, 212, 0.2);
  }
  .home-container-1-component-container27 {
    width: 68px;
    height: 100%;
    align-items: center;
    justify-content: center;
  }
  .home-container-1-component-icon10 {
    width: 30px;
    height: 30px;
  }
  .home-container-1-component-container28 {
    flex: 1;
    height: 100%;
    align-items: center;
    padding-right: 20px;
  }
  .home-container-1-component-text10 {
    width: 100%;
    font-size: 16px;
    line-height: 25px;
  }
  .home-container-1-component-container29 {
    width: 100%;
    padding-top: var(--dl-space-space-p20);
    padding-left: var(--dl-space-space-p30);
    padding-right: var(--dl-space-space-p30);
    padding-bottom: var(--dl-space-space-p20);
    justify-content: center;
    background-color: rgba(217, 217, 217, 0.2);
  }
  .home-container-1-component-text11 {
    text-align: left;
  }
  .home-container-1-component-icon14 {
    width: 18px;
    height: 18px;
  }
}
