@tailwind base;
@tailwind components;
@tailwind utilities;
 
 
 :root {
  --dl-color-gray-500: #595959;
  --dl-color-gray-700: #999999;
  --dl-color-gray-900: #D9D9D9;
  --dl-color-gray-text: #514565ff;
  --dl-size-size-large: 144px;
  --dl-size-size-small: 48px;
  --dl-space-space-p20: 20px;
  --dl-space-space-p30: 30px;
  --dl-space-space-p40: 40px;
  --dl-space-space-p60: 60px;
  --dl-color-danger-300: #A22020;
  --dl-color-danger-500: #BF2626;
  --dl-color-danger-700: #E14747;
  --dl-color-gray-black: #000000;
  --dl-color-gray-white: #FFFFFF;
  --dl-size-size-medium: 96px;
  --dl-size-size-xlarge: 192px;
  --dl-size-size-xsmall: 16px;
  --dl-space-space-unit: 16px;
  --dl-color-gray-border: #d6d0daff;
  --dl-color-success-300: #199033;
  --dl-color-success-500: #32A94C;
  --dl-color-success-700: #4CC366;
  --dl-size-size-xxlarge: 288px;
  --dl-color-primary-blue: #0066fcff;
  --dl-color-primary-lila: #6840a5ff;
  --dl-size-size-maxwidth: 1400px;
  --dl-color-gray-border-2: #d1ddf2ff;
  --dl-radius-radius-round: 50%;
  --dl-color-primary-orange: #f65814ff;
  --dl-space-space-halfunit: 8px;
  --dl-space-space-sixunits: 96px;
  --dl-space-space-twounits: 32px;
  --dl-color-gray-background: #f7f9fcff;
  --dl-radius-radius-radius2: 2px;
  --dl-radius-radius-radius4: 4px;
  --dl-radius-radius-radius8: 8px;
  --dl-space-space-fiveunits: 80px;
  --dl-space-space-fourunits: 64px;
  --dl-space-space-threeunits: 48px;
  --dl-space-space-oneandhalfunits: 24px;
}
.button {
  color: var(--dl-color-gray-black);
  display: inline-block;
  padding: 0.5rem 1rem;
  border-color: var(--dl-color-gray-black);
  border-width: 1px;
  border-radius: 4px;
  background-color: var(--dl-color-gray-white);
}
.input {
  cursor: auto;
  background-color: transparent;
}
.textarea {
  color: var(--dl-color-gray-black);
  cursor: auto;
  padding: 0.5rem;
  border-color: var(--dl-color-gray-black);
  border-width: 1px;
  border-radius: 4px;
  background-color: var(--dl-color-gray-white);
}
.list {
  width: 100%;
  display: block;
  list-style-type: none;
  list-style-position: outside;
}
.list-item {
  display: list-item;
}
.teleport-show {
  display: flex !important;
  transform: none !important;
}
.componet {
  gap: 20px;
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background-color: #fff;
}
.botton {
  width: 200px;
  height: 40px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background-color: #D9D9D9;
}
.text {
  color: #000;
  font-size: 14px;
  font-family: inter;
  font-weight: 400;
}
.titel-1 {
  font-size: 40px;
  font-style: normal;
  font-family: Poppins;
  font-weight: 600;
  line-height: 55px;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2);
  letter-spacing: 1.3;
}
.titel-2 {
  font-size: 35px;
  font-family: Poppins;
  font-weight: 600;
  line-height: 50px;
  letter-spacing: 1.3;
}
.titel-2-mobil {
  font-size: 20px;
  font-style: normal;
  font-family: Poppins;
  font-weight: 600;
  line-height: 35px;
  letter-spacing: 1.3;
}
.ueberschrift-1-mobil {
  font-size: 16px;
  font-style: normal;
  font-family: Poppins;
  font-weight: 600;
  line-height: 25px;
  letter-spacing: 1.3;
}
.titel-text-1 {
  font-size: 22px;
  font-style: normal;
  font-weight: 500;
  line-height: 40px;
}
.ueberschrift-1 {
  font-size: 30px;
  font-style: normal;
  font-family: Inter;
  font-weight: 600;
  line-height: 40px;
}
.ueberschrift-2 {
  font-size: 22px;
  font-style: normal;
  font-family: Inter;
  font-weight: 600;
  line-height: 28px;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.2);
}
.ueberschrift-3 {
  font-size: 16px;
  font-style: normal;
  font-family: Inter;
  font-weight: 600;
  line-height: 25px;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.1);
}
.sub-titel-1 {
  font-size: 12px;
  font-style: normal;
  font-family: Poppins;
  font-weight: 600;
  line-height: 15px;
}
.text-1 {
  opacity: 0.9;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px;
  letter-spacing: 1.3;
}
.text-2 {
  opacity: 0.9;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 1.3;
}
.gradient {
  background-image: linear-gradient(45deg, rgb(194, 165, 246) 4.00%,rgb(95, 56, 164) 98.00%);
}
.gradient2 {
  background-image: linear-gradient(0deg, rgba(189, 195, 199, 0) 0.00%,rgb(232, 240, 255) 100.00%);
}
.label-1 {
  color: #F7F8FA;
  font-size: 120px;
  font-style: normal;
  font-family: Poppins;
  font-weight: 700;
  line-height: 180px;
}
.menu-btn-enable {
  gap: 10px;
  fill: var(--dl-color-primary-blue);
  flex: 0 0 auto;
  color: var(--dl-color-primary-blue);
  width: 100%;
  height: 45px;
  display: flex;
  align-items: center;
  border-color: var(--dl-color-gray-border-2);
  border-width: 1px;
  padding-left: var(--dl-space-space-p20);
  border-radius: var(--dl-radius-radius-radius8);
  padding-right: var(--dl-space-space-p20);
  text-decoration: none;
  background-color: #F7F9FC;
}
.menu-btn-disable {
  gap: 10px;
  flex: 0 0 auto;
  width: 100%;
  height: 45px;
  display: flex;
  opacity: 0.4;
  transition: 0.1s;
  align-items: center;
  padding-left: var(--dl-space-space-p20);
  border-radius: var(--dl-radius-radius-radius8);
  padding-right: var(--dl-space-space-p20);
  text-decoration: none;
}
.menu-btn-disable:hover {
  opacity: 0.6;
  background-color: #F7F9FC;
}
.menu-bar-btn-enable {
  cursor: pointer;
  display: flex;
  font-size: 14px;
  align-items: center;
  font-weight: 700;
  line-height: 18px;
  padding-top: 5px;
  border-color: rgba(255, 255, 255, 0.7);
  border-width: 2px;
  padding-left: 10px;
  padding-right: 10px;
  letter-spacing: 1.3;
  padding-bottom: 5px;
  justify-content: space-between;
  text-decoration: none;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
}
.menu-bar-btn-disable {
  cursor: pointer;
  display: flex;
  opacity: 0.8;
  font-size: 14px;
  transition: 0.1s;
  align-items: center;
  font-weight: 300;
  line-height: 18px;
  padding-top: 5px;
  border-color: transparent;
  border-width: 2px;
  padding-left: 10px;
  padding-right: 10px;
  letter-spacing: 1.3;
  padding-bottom: 5px;
  justify-content: space-between;
  text-decoration: none;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
}
.menu-bar-btn-disable:hover {
  opacity: 100;
}
.menu-bar-btn-disable:active {
  opacity: 100;
}
.box-shadow {
  box-shadow: 0px 0px 10px 0px rgba(161, 161, 161, 0.3);
}
.dorpdown-menu-button-disable {
  flex: 1;
  width: 100%;
  cursor: pointer;
  height: 100px;
  display: flex;
  align-items: flex-start;
  border-color: var(--dl-color-gray-border-2);
  border-width: 1px;
  padding-left: 10px;
  border-radius: var(--dl-radius-radius-radius8);
  flex-direction: column;
  justify-content: center;
}
.dorpdown-menu-button-enable {
  flex: 1;
  color: var(--dl-color-gray-white);
  width: 100%;
  height: 100px;
  display: flex;
  box-shadow: 0px 0px 10px 0px rgba(161, 161, 161, 0.3);
  align-items: flex-start;
  padding-left: 10px;
  border-radius: var(--dl-radius-radius-radius8);
  flex-direction: column;
  justify-content: center;
  background-image: linear-gradient(45deg, rgb(194, 165, 246) 4.00%,rgb(95, 56, 164) 98.00%);
}
.button-lila {
  gap: 10px;
  fill: var(--dl-color-gray-white);
  flex: 0 0 auto;
  color: var(--dl-color-gray-white);
  cursor: pointer;
  display: flex;
  align-items: center;
  padding-top: 10px;
  padding-left: 15px;
  border-radius: 30px;
  padding-right: 15px;
  padding-bottom: 10px;
  justify-content: center;
  background-color: var(--dl-color-primary-lila);
}
.button-lila:hover {
  opacity: 0.7;
}
.box-shadow-dark {
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.4);
}
.button-blue {
  gap: 10px;
  fill: var(--dl-color-gray-white);
  flex: 0 0 auto;
  color: var(--dl-color-gray-white);
  width: auto;
  cursor: pointer;
  height: auto;
  display: flex;
  transition: 0.1s;
  align-items: center;
  padding-top: 5px;
  padding-left: 15px;
  border-radius: 30px;
  padding-right: 15px;
  padding-bottom: 5px;
  justify-content: center;
  background-color: #0066FC;
}
.button-blue:hover {
  opacity: 0.7;
}
.tool-box {
  gap: 20px;
  flex: 1;
  cursor: pointer;
  height: 100%;
  display: flex;
  transition: 0.1s;
  align-items: flex-start;
  border-color: var(--dl-color-gray-border-2);
  border-width: 1px;
  padding-left: 40px;
  border-radius: var(--dl-radius-radius-radius8);
  padding-right: 40px;
  flex-direction: column;
  padding-bottom: var(--dl-space-space-p20);
  justify-content: center;
}
.tool-box:hover {
  box-shadow: 0px 0px 10px 0px rgba(161, 161, 161, 0.3);
  background-color: rgba(255, 255, 255, 0.5);
}
.border-b {
  border-color: var(--dl-color-gray-border-2);
  border-bottom-width: 1;
}
.text-1-mobil {
  opacity: 1;
  text-shadow: 1px 1px rgba(0, 0, 0, 0.3);
}
.Heading {
  font-size: 32px;
  font-family: Inter;
  font-weight: 700;
  line-height: 1.15;
  text-transform: none;
  text-decoration: none;
}
.Content {
  font-size: 16px;
  font-family: Inter;
  font-weight: 400;
  line-height: 1.3;
  text-transform: none;
  text-decoration: none;
}

@media(max-width: 479px) {
  .ueberschrift-1 {
    font-size: 25px;
    line-height: 35px;
  }
  .text-1-mobil {
    font-size: 14px;
    text-align: center;
    line-height: 25px;
  }
}
