.imprint-container {
  gap: var(--dl-space-space-halfunit);
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.imprint-web {
  flex: 1;
  width: 100%;
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: column;
}
.imprint-container01 {
  color: var(--dl-color-gray-white);
  width: 100%;
  height: 430px;
  display: flex;
  overflow: hidden;
  align-items: center;
  flex-direction: column;
  background-size: cover;
  background-image: url("/bg/img_9.svg");
}
.imprint-container02 {
  flex: 1;
  width: 100%;
  height: 0px;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.imprint-container03 {
  gap: 40px;
  width: 1360px;
  display: flex;
  align-items: center;
  padding-left: 100px;
  padding-right: 100px;
  flex-direction: column;
  justify-content: center;
}
.imprint-container04 {
  gap: 30px;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.imprint-container05 {
  gap: 20px;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.imprint-container06 {
  color: var(--dl-color-gray-text);
  display: flex;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
  align-items: flex-start;
  padding-top: 5px;
  padding-left: 15px;
  border-radius: 20px;
  padding-right: 15px;
  flex-direction: column;
  padding-bottom: 5px;
  background-image: linear-gradient(315deg, rgba(255, 255, 255, 0.5) 0.00%,rgb(255, 255, 255) 99.00%);
}
.imprint-text {
  font-style: normal;
  font-weight: 600;
}
.imprint-text01 {
  text-align: center;
}
.imprint-text02 {
  text-align: center;
}
.imprint-container07 {
  flex: 0 0 auto;
  width: 100%;
  height: 20px;
  display: flex;
  box-shadow: 0px -5px 10px 0px rgba(66, 66, 66, 0.3);
  align-items: flex-start;
  border-color: var(--dl-color-gray-border);
  border-width: 1px;
  background-color: rgba(243, 243, 243, 0.63);
  border-left-width: 0px;
  border-right-width: 0px;
}
.imprint-container08 {
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-p60);
  padding-bottom: var(--dl-space-space-p60);
  justify-content: center;
}
.imprint-container09 {
  gap: 30px;
  flex: 0 0 auto;
  width: 80%;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.imprint-text03 {
  text-align: center;
}
.imprint-text04 {
  text-align: center;
}
.imprint-text05 {
  text-align: center;
}
.imprint-text11 {
  text-align: center;
}
.imprint-text15 {
  text-align: center;
}
.imprint-text16 {
  font-style: normal;
  font-weight: 700;
}
.imprint-text19 {
  text-align: center;
}
.imprint-text20 {
  text-align: center;
}
.imprint-text21 {
  text-align: center;
}
.imprint-text22 {
  text-align: center;
}
.imprint-container10 {
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-p40);
  padding-bottom: var(--dl-space-space-p40);
  background-size: cover;
  justify-content: center;
  background-image: url("/rectangle%20166-1500w.png");
}
.imprint-container11 {
  flex: 0 0 auto;
  width: 80%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.imprint-container12 {
  gap: 20px;
  flex: 1;
  color: var(--dl-color-gray-white);
  height: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
.imprint-text27 {
  opacity: 0.8;
}
.imprint-container13 {
  gap: 10px;
  width: 526px;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.imprint-container14 {
  gap: 5px;
  flex: 0 0 auto;
  color: var(--dl-color-gray-white);
  width: 100%;
  height: 45px;
  display: flex;
  align-items: center;
  padding-left: var(--dl-space-space-p20);
  border-radius: 35px;
  flex-direction: row;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.23);
}
.imprint-textinput {
  width: 100%;
  height: 100%;
  background-color: transparent;
}
.imprint-container15 {
  gap: 10px;
  flex: 0 0 auto;
  width: 100%;
  height: 45px;
  display: flex;
  align-items: center;
  border-radius: 35px;
  flex-direction: row;
  justify-content: center;
  background-color: var(--dl-color-gray-white);
}
.imprint-text31 {
  font-size: 18px;
  line-height: 22px;
}
.imprint-icon {
  width: 24px;
  height: 24px;
}
.imprint-mobil {
  flex: 0 0 auto;
  width: 200px;
  height: 100px;
  display: none;
  align-items: flex-start;
  flex-direction: column;
}
.imprint-container16 {
  display: flex;
  position: absolute;
}
.imprint-top {
  width: 100%;
  display: flex;
  position: relative;
}
.imprint-container17 {
  display: flex;
}
.imprint-container18 {
  width: 100%;
  display: flex;
}
.imprint-container19 {
  gap: 20px;
  width: 100%;
  height: auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.imprint-container20 {
  color: var(--dl-color-gray-text);
  display: flex;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
  align-items: flex-start;
  padding-top: 5px;
  padding-left: 15px;
  border-radius: 20px;
  padding-right: 15px;
  flex-direction: column;
  padding-bottom: 5px;
  background-image: linear-gradient(315deg, rgba(255, 255, 255, 0.5) 0.00%,rgb(255, 255, 255) 99.00%);
}
.imprint-text32 {
  font-style: normal;
  font-weight: 600;
}
.imprint-container21 {
  gap: 20px;
  color: white;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.imprint-text33 {
  text-shadow: 2px 2px rgba(0, 0, 0, 0.2);
}
.imprint-content {
  flex: 0 0 auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.imprint-container22 {
  flex: 0 0 auto;
  width: 100%;
  height: 20px;
  display: flex;
  box-shadow: 0px -5px 10px 0px rgba(66, 66, 66, 0.3);
  align-items: flex-start;
  border-color: var(--dl-color-gray-border);
  border-width: 1px;
  background-color: rgba(243, 243, 243, 0.63);
  border-left-width: 0px;
  border-right-width: 0px;
}
.imprint-container23 {
  gap: 30px;
  flex: 0 0 auto;
  width: 80%;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.imprint-text35 {
  text-align: center;
}
.imprint-text36 {
  text-align: center;
}
.imprint-text37 {
  text-align: center;
}
.imprint-text43 {
  text-align: center;
}
.imprint-text47 {
  text-align: center;
}
.imprint-text48 {
  font-style: normal;
  font-weight: 700;
}
.imprint-text51 {
  text-align: center;
}
.imprint-text52 {
  text-align: center;
}
.imprint-text53 {
  text-align: center;
}
.imprint-text57 {
  text-align: center;
}
@media(max-width: 1440px) {
  .imprint-container03 {
    width: 1110px;
    padding-left: 100px;
    padding-right: 100px;
  }
  .imprint-container04 {
    width: 891px;
  }
  .imprint-text01 {
    font-size: 30px;
    line-height: 45px;
  }
}
@media(max-width: 479px) {
  .imprint-web {
    display: none;
  }
  .imprint-mobil {
    flex: 1;
    width: 100%;
    display: flex;
  }
  .imprint-container16 {
    width: 100%;
  }
  .imprint-top {
    height: auto;
    background-image: url("/bg/img_9.svg");
    background-repeat: no-repeat;
    background-position: top bottom;
  }
  .imprint-container17 {
    height: auto;
    align-items: center;
    flex-direction: column;
  }
  .imprint-container18 {
    margin-top: 70px;
    padding-top: var(--dl-space-space-p40);
    padding-left: var(--dl-space-space-p30);
    padding-right: var(--dl-space-space-p30);
    padding-bottom: var(--dl-space-space-p40);
  }
  .imprint-container19 {
    align-items: center;
  }
  .imprint-text32 {
    font-style: normal;
    font-weight: 600;
  }
  .imprint-text33 {
    font-size: 25px;
    text-align: center;
  }
  .imprint-text34 {
    color: rgba(255, 255, 255, 0.7);
  }
  .imprint-content {
    width: 100%;
    padding-bottom: 0px;
  }
  .imprint-container23 {
    width: 100%;
    padding-top: var(--dl-space-space-p60);
    padding-left: var(--dl-space-space-p30);
    padding-right: var(--dl-space-space-p30);
    padding-bottom: var(--dl-space-space-p60);
  }
}
