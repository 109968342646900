.dorpdown-box-component-dorpdown-box-component {
  gap: 10px;
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.dorpdown-box-component-container {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  overflow: hidden;
  align-items: flex-start;
  border-radius: var(--dl-radius-radius-radius8);
  flex-direction: column;
}
.dorpdown-box-component-container01 {
  width: 100%;
  height: 70px;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-direction: column;
}
.dorpdown-box-component-container02 {
  fill: var(--dl-color-gray-white);
  flex: 0 0 auto;
  color: var(--dl-color-gray-white);
  width: 100%;
  height: 100%;
  display: flex;
  z-index: 90;
  align-items: center;
  padding-left: var(--dl-space-space-p20);
  padding-right: var(--dl-space-space-p20);
  justify-content: space-between;
  background-color: var(--dl-color-gray-text);
}
.dorpdown-box-component-container03 {
  border: 2px dashed rgba(120, 120, 120, 0.4);
  display: flex;
  align-items: center;
}
.dorpdown-box-component-container04 {
  flex: 0 0 auto;
  width: 100%;
  height: 100%;
  display: flex;
  position: absolute;
  align-items: center;
  border-color: var(--dl-color-gray-border-2);
  border-width: 1px;
  padding-left: var(--dl-space-space-p20);
  border-radius: var(--dl-radius-radius-radius8);
  padding-right: var(--dl-space-space-p20);
  justify-content: space-between;
  background-color: var(--dl-color-gray-white);
}
.dorpdown-box-component-container05 {
  display: flex;
  align-items: center;
}
.dorpdown-box-component-icon {
  width: 24px;
  cursor: pointer;
  height: 24px;
}
.dorpdown-box-component-container06 {
  flex: 1;
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-p20);
  align-items: flex-start;
  border-color: var(--dl-color-gray-border-2);
  border-width: 1px;
  border-radius: var(--dl-radius-radius-radius8);
  flex-direction: column;
  background-color: var(--dl-color-gray-white);
  border-top-width: 0px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.dorpdown-box-component-container07 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  overflow: hidden;
  align-items: flex-start;
  border-radius: var(--dl-radius-radius-radius8);
  flex-direction: column;
}
.dorpdown-box-component-container08 {
  width: 100%;
  height: 70px;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-direction: column;
}
.dorpdown-box-component-container09 {
  fill: var(--dl-color-gray-white);
  flex: 0 0 auto;
  color: var(--dl-color-gray-white);
  width: 100%;
  height: 100%;
  display: flex;
  z-index: 90;
  align-items: center;
  padding-left: var(--dl-space-space-p20);
  padding-right: var(--dl-space-space-p20);
  justify-content: space-between;
  background-color: var(--dl-color-gray-text);
}
.dorpdown-box-component-container10 {
  border: 2px dashed rgba(120, 120, 120, 0.4);
  display: flex;
  align-items: center;
}
.dorpdown-box-component-container11 {
  flex: 0 0 auto;
  width: 100%;
  height: 100%;
  display: flex;
  position: absolute;
  align-items: center;
  border-color: var(--dl-color-gray-border-2);
  border-width: 1px;
  padding-left: var(--dl-space-space-p20);
  border-radius: var(--dl-radius-radius-radius8);
  padding-right: var(--dl-space-space-p20);
  justify-content: space-between;
  background-color: var(--dl-color-gray-white);
}
.dorpdown-box-component-container12 {
  display: flex;
  align-items: center;
}
.dorpdown-box-component-icon2 {
  width: 24px;
  cursor: pointer;
  height: 24px;
}
.dorpdown-box-component-container13 {
  flex: 1;
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-p20);
  align-items: flex-start;
  border-color: var(--dl-color-gray-border-2);
  border-width: 1px;
  border-radius: var(--dl-radius-radius-radius8);
  flex-direction: column;
  background-color: var(--dl-color-gray-white);
  border-top-width: 0px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.dorpdown-box-component-container14 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  overflow: hidden;
  align-items: flex-start;
  border-radius: var(--dl-radius-radius-radius8);
  flex-direction: column;
}
.dorpdown-box-component-container15 {
  width: 100%;
  height: 70px;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-direction: column;
}
.dorpdown-box-component-container16 {
  fill: var(--dl-color-gray-white);
  flex: 0 0 auto;
  color: var(--dl-color-gray-white);
  width: 100%;
  height: 100%;
  display: flex;
  z-index: 90;
  align-items: center;
  padding-left: var(--dl-space-space-p20);
  padding-right: var(--dl-space-space-p20);
  justify-content: space-between;
  background-color: var(--dl-color-gray-text);
}
.dorpdown-box-component-container17 {
  border: 2px dashed rgba(120, 120, 120, 0.4);
  display: flex;
  align-items: center;
}
.dorpdown-box-component-container18 {
  flex: 0 0 auto;
  width: 100%;
  height: 100%;
  display: flex;
  position: absolute;
  align-items: center;
  border-color: var(--dl-color-gray-border-2);
  border-width: 1px;
  padding-left: var(--dl-space-space-p20);
  border-radius: var(--dl-radius-radius-radius8);
  padding-right: var(--dl-space-space-p20);
  justify-content: space-between;
  background-color: var(--dl-color-gray-white);
}
.dorpdown-box-component-container19 {
  display: flex;
  align-items: center;
}
.dorpdown-box-component-icon4 {
  width: 24px;
  cursor: pointer;
  height: 24px;
}
.dorpdown-box-component-container20 {
  flex: 1;
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-p20);
  align-items: flex-start;
  border-color: var(--dl-color-gray-border-2);
  border-width: 1px;
  border-radius: var(--dl-radius-radius-radius8);
  flex-direction: column;
  background-color: var(--dl-color-gray-white);
  border-top-width: 0px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}




