.contact-us-mini-form-component-contact-us-mini-form-component {
  flex: 0 0 auto;
  width: 650px;
  height: 55px;
  display: flex;
  overflow: hidden;
  position: relative;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
  align-items: flex-start;
  border-radius: var(--dl-radius-radius-radius8);
  background-color: var(--dl-color-gray-white);
}
.contact-us-mini-form-component-container {
  flex: 1;
  height: 100%;
  display: flex;
  align-items: center;
  padding-left: 20px;
}
.contact-us-mini-form-component-textinput {
  width: 100%;
}
.contact-us-mini-form-component-container1 {
  flex: 0 0 auto;
  color: white;
  width: 200px;
  height: 100%;
  display: flex;
  box-shadow: -1px 0px 4px 0px rgba(0, 0, 0, 0.43);
  align-items: center;
  border-radius: var(--dl-radius-radius-radius8);
  flex-direction: column;
  justify-content: center;
  background-color: var(--dl-color-primary-lila);
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
@media(max-width: 1440px) {
  .contact-us-mini-form-component-contact-us-mini-form-component {
    width: 618px;
    height: 55px;
  }
}
