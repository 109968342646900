.login-container {
  gap: var(--dl-space-space-halfunit);
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.login-container01 {
  flex: 1;
  width: 100%;
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: column;
}
.login-container02 {
  gap: 50px;
  color: var(--dl-color-gray-white);
  width: 100%;
  height: 813px;
  display: flex;
  overflow: hidden;
  align-items: center;
  flex-direction: column;
  background-size: cover;
  background-image: url("/frame%20310-900h.png");
}
.login-container03 {
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  padding-left: var(--dl-space-space-p60);
  flex-direction: row;
  justify-content: center;
}
.login-container04 {
  color: var(--dl-color-gray-text);
  width: 530px;
  height: auto;
  display: flex;
  align-items: flex-start;
  border-color: var(--dl-color-gray-border);
  border-width: 1px;
  border-radius: 20px;
  flex-direction: column;
  background-color: var(--dl-color-gray-white);
}
.login-container05 {
  gap: 20px;
  width: 100%;
  height: 149px;
  display: flex;
  box-shadow: 0px 4px 3px -1px rgba(233, 233, 233, 0.33);
  align-items: center;
  border-color: var(--dl-color-gray-border-2);
  border-width: 1px;
  flex-direction: column;
  justify-content: center;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
}
.login-text {
  font-style: normal;
  font-weight: 700;
}
.login-container06 {
  gap: var(--dl-space-space-p30);
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-p40);
  padding-left: var(--dl-space-space-p40);
  padding-right: var(--dl-space-space-p40);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-p20);
}
.login-container07 {
  gap: 10px;
  width: 100%;
  height: auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.login-text2 {
  font-style: normal;
  font-weight: 600;
}
.login-container08 {
  flex: 0 0 auto;
  width: 100%;
  height: 45px;
  display: flex;
  align-items: center;
  border-color: var(--dl-color-gray-border-2);
  border-style: solid;
  border-width: 1px;
  padding-left: var(--dl-space-space-p20);
  border-radius: var(--dl-radius-radius-radius8);
  padding-right: var(--dl-space-space-p20);
  background-color: var(--dl-color-gray-background);
}
.login-textinput {
  width: 100%;
}
.login-container09 {
  gap: 10px;
  width: 100%;
  height: auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.login-text3 {
  font-style: normal;
  font-weight: 600;
}
.login-container10 {
  flex: 0 0 auto;
  width: 100%;
  height: 45px;
  display: flex;
  align-items: center;
  border-color: var(--dl-color-gray-border-2);
  border-style: solid;
  border-width: 1px;
  padding-left: var(--dl-space-space-p20);
  border-radius: var(--dl-radius-radius-radius8);
  padding-right: var(--dl-space-space-p20);
  background-color: var(--dl-color-gray-background);
}
.login-textinput1 {
  width: 100%;
}
.login-container11 {
  width: 100%;
  height: 59px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.login-container12 {
  gap: 10px;
  display: flex;
  align-items: center;
}
.login-container13 {
  flex: 0 0 auto;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: flex-start;
  border-color: var(--dl-color-gray-border-2);
  border-width: 1px;
  border-radius: var(--dl-radius-radius-radius4);
  flex-direction: column;
  background-color: var(--dl-color-gray-white);
}
.login-text5 {
  color: var(--dl-color-primary-blue);
}
.login-container14 {
  flex: 0 0 auto;
  width: 100%;
  height: 100px;
  display: flex;
  box-shadow: 0px -4px 3px -1px rgba(233, 233, 233, 0.33);
  align-items: center;
  border-color: var(--dl-color-gray-border-2);
  border-width: 1px;
  padding-left: var(--dl-space-space-p40);
  padding-right: var(--dl-space-space-p40);
  justify-content: center;
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 0px;
}
.login-navlink {
  display: contents;
}
.login-container15 {
  gap: 10px;
  fill: var(--dl-color-gray-white);
  flex: 0 0 auto;
  color: var(--dl-color-gray-white);
  width: 100%;
  height: 45px;
  display: flex;
  align-items: center;
  border-radius: 30px;
  justify-content: center;
  text-decoration: none;
  background-color: #0066FC;
}
@media(max-width: 1440px) {
  .login-container02 {
    height: 828px;
  }
}
