.contact-us-container {
  gap: var(--dl-space-space-halfunit);
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.contact-us-web {
  flex: 1;
  width: 100%;
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: column;
}
.contact-us-container01 {
  color: var(--dl-color-gray-white);
  width: 100%;
  height: 430px;
  display: flex;
  overflow: hidden;
  align-items: center;
  flex-direction: column;
  background-size: cover;
  background-image: url("/bg/img_9.svg");
}
.contact-us-container02 {
  flex: 1;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.contact-us-container03 {
  gap: 40px;
  width: 1360px;
  display: flex;
  align-items: center;
  padding-left: 100px;
  padding-right: 100px;
  flex-direction: column;
  justify-content: center;
}
.contact-us-container04 {
  gap: 30px;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.contact-us-container05 {
  gap: 20px;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.contact-us-container06 {
  color: var(--dl-color-gray-text);
  display: flex;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
  align-items: flex-start;
  padding-top: 5px;
  padding-left: 15px;
  border-radius: 20px;
  padding-right: 15px;
  flex-direction: column;
  padding-bottom: 5px;
  background-image: linear-gradient(315deg, rgba(255, 255, 255, 0.5) 0.00%,rgb(255, 255, 255) 99.00%);
}
.contact-us-text {
  font-style: normal;
  font-weight: 600;
}
.contact-us-text01 {
  text-align: center;
}
.contact-us-text02 {
  text-align: center;
}
.contact-us-container07 {
  width: 100%;
  display: none;
  z-index: 90;
  box-shadow: 0px 0px 10px 0px rgba(66, 66, 66, 0.3);
  align-items: center;
  padding-top: var(--dl-space-space-p20);
  border-color: var(--dl-color-gray-border-2);
  border-width: 1px;
  flex-direction: column;
  padding-bottom: var(--dl-space-space-p20);
  justify-content: flex-end;
  border-left-width: 0px;
  border-right-width: 0px;
}
.contact-us-text03 {
  opacity: 0.6;
}
.contact-us-container08 {
  gap: 40px;
  width: 100%;
  display: flex;
  opacity: 0.4;
  align-items: center;
  justify-content: center;
}
.contact-us-text04 {
  font-size: 20px;
  text-shadow: 1px 1px rgba(0, 0, 0, 0.2);
}
.contact-us-text05 {
  font-size: 20px;
  text-shadow: 1px 1px rgba(0, 0, 0, 0.2);
}
.contact-us-text06 {
  font-size: 20px;
  text-shadow: 1px 1px rgba(0, 0, 0, 0.2);
}
.contact-us-text07 {
  font-size: 20px;
  text-shadow: 1px 1px rgba(0, 0, 0, 0.2);
}
.contact-us-text08 {
  font-size: 20px;
  text-shadow: 1px 1px rgba(0, 0, 0, 0.2);
}
.contact-us-text09 {
  font-size: 20px;
  text-shadow: 1px 1px rgba(0, 0, 0, 0.2);
}
.contact-us-text10 {
  font-size: 20px;
  text-shadow: 1px 1px rgba(0, 0, 0, 0.2);
}
.contact-us-text11 {
  font-size: 20px;
  text-shadow: 1px 1px rgba(0, 0, 0, 0.2);
}
.contact-us-container09 {
  flex: 0 0 auto;
  width: 100%;
  height: 20px;
  display: flex;
  box-shadow: 0px -5px 10px 0px rgba(66, 66, 66, 0.3);
  align-items: flex-start;
  border-color: var(--dl-color-gray-border);
  border-width: 1px;
  background-color: rgba(243, 243, 243, 0.63);
  border-left-width: 0px;
  border-right-width: 0px;
}
.contact-us-container10 {
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  padding-top: 80px;
  padding-bottom: 80px;
  justify-content: center;
}
.contact-us-container11 {
  flex: 0 0 auto;
  width: 80%;
  height: 612px;
  display: flex;
  align-items: flex-start;
}
.contact-us-container12 {
  gap: 40px;
  flex: 1;
  width: 200px;
  height: auto;
  display: flex;
  align-items: flex-start;
  padding-left: var(--dl-space-space-p40);
  padding-right: var(--dl-space-space-p40);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-p30);
}
.contact-us-container13 {
  gap: 10px;
  width: 100%;
  height: auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.contact-us-container14 {
  gap: 20px;
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.contact-us-container15 {
  gap: 30px;
  width: 100%;
  height: 80px;
  display: flex;
  align-items: center;
}
.contact-us-container16 {
  fill: var(--dl-color-gray-white);
  flex: 0 0 auto;
  width: 65px;
  height: 65px;
  display: flex;
  align-items: center;
  border-color: #D1DDF2;
  border-width: 1px;
  border-radius: var(--dl-radius-radius-round);
  flex-direction: column;
  justify-content: center;
  background-color: #F8FAFB;
}
.contact-us-icon {
  width: 30px;
  height: 30px;
}
.contact-us-container17 {
  gap: 5px;
  flex: 1;
  width: 200px;
  height: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
.contact-us-text16 {
  opacity: 0.6;
  font-size: 12px;
}
.contact-us-container18 {
  gap: 30px;
  flex: 1;
  width: 200px;
  height: 100%;
  display: flex;
  align-items: center;
  padding-left: var(--dl-space-space-p20);
  padding-right: var(--dl-space-space-p20);
  flex-direction: column;
}
.contact-us-container19 {
  flex: 0 0 auto;
  width: 100%;
  height: 70px;
  display: flex;
  align-items: center;
  border-color: #D1DDF2;
  border-width: 1px;
  padding-left: var(--dl-space-space-p30);
  border-radius: var(--dl-radius-radius-radius4);
  padding-right: var(--dl-space-space-p30);
  background-color: #FAFBFE;
}
.contact-us-textinput {
  width: 100%;
  height: 100%;
  background-color: transparent;
}
.contact-us-container20 {
  flex: 0 0 auto;
  width: 100%;
  height: 70px;
  display: flex;
  align-items: center;
  border-color: #D1DDF2;
  border-width: 1px;
  padding-left: var(--dl-space-space-p30);
  border-radius: var(--dl-radius-radius-radius4);
  padding-right: var(--dl-space-space-p30);
  background-color: #FAFBFE;
}
.contact-us-textinput1 {
  width: 100%;
  height: 100%;
  background-color: transparent;
}
.contact-us-container21 {
  flex: 0 0 auto;
  width: 100%;
  height: 70px;
  display: flex;
  align-items: center;
  border-color: #D1DDF2;
  border-width: 1px;
  padding-left: var(--dl-space-space-p30);
  border-radius: var(--dl-radius-radius-radius4);
  padding-right: var(--dl-space-space-p30);
  background-color: #FAFBFE;
}
.contact-us-textinput2 {
  width: 100%;
  height: 100%;
  background-color: transparent;
}
.contact-us-container22 {
  flex: 1;
  width: 100%;
  height: 70px;
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-p20);
  border-color: #D1DDF2;
  border-width: 1px;
  padding-left: var(--dl-space-space-p30);
  border-radius: var(--dl-radius-radius-radius4);
  padding-right: var(--dl-space-space-p30);
  background-color: #FAFBFE;
}
.contact-us-textinput3 {
  width: 100%;
  background-color: transparent;
}
.contact-us-container23 {
  gap: 10px;
  fill: var(--dl-color-gray-white);
  flex: 0 0 auto;
  color: var(--dl-color-gray-white);
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  border-radius: 30px;
  justify-content: center;
  background-color: #0066FC;
}
.contact-us-text18 {
  font-size: 18px;
}
.contact-us-icon2 {
  width: 24px;
  height: 24px;
}
.contact-us-mobil {
  flex: 0 0 auto;
  width: 200px;
  height: 100px;
  display: none;
  align-items: flex-start;
  flex-direction: column;
}
.contact-us-container24 {
  display: flex;
  position: absolute;
}
.contact-us-top {
  width: 100%;
  display: flex;
  position: relative;
}
.contact-us-container25 {
  display: flex;
}
.contact-us-container26 {
  width: 100%;
  display: flex;
}
.contact-us-container27 {
  gap: 20px;
  width: 100%;
  height: auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.contact-us-container28 {
  color: var(--dl-color-gray-text);
  display: flex;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
  align-items: flex-start;
  padding-top: 5px;
  padding-left: 15px;
  border-radius: 20px;
  padding-right: 15px;
  flex-direction: column;
  padding-bottom: 5px;
  background-image: linear-gradient(315deg, rgba(255, 255, 255, 0.5) 0.00%,rgb(255, 255, 255) 99.00%);
}
.contact-us-text19 {
  font-style: normal;
  font-weight: 600;
}
.contact-us-container29 {
  gap: 20px;
  color: white;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.contact-us-text20 {
  text-shadow: 2px 2px rgba(0, 0, 0, 0.2);
}
.contact-us-content {
  flex: 0 0 auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.contact-us-container30 {
  flex: 0 0 auto;
  width: 100%;
  height: 20px;
  display: flex;
  box-shadow: 0px -5px 10px 0px rgba(66, 66, 66, 0.3);
  align-items: flex-start;
  border-color: var(--dl-color-gray-border);
  border-width: 1px;
  background-color: rgba(243, 243, 243, 0.63);
  border-left-width: 0px;
  border-right-width: 0px;
}
.contact-us-container31 {
  flex: 0 0 auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.contact-us-container32 {
  gap: 10px;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.contact-us-text22 {
  text-align: center;
}
.contact-us-text23 {
  text-align: center;
}
.contact-us-container33 {
  gap: 30px;
  flex: 1;
  width: 200px;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.contact-us-container34 {
  flex: 0 0 auto;
  width: 100%;
  height: 70px;
  display: flex;
  align-items: center;
  border-color: #D1DDF2;
  border-width: 1px;
  padding-left: var(--dl-space-space-p30);
  border-radius: var(--dl-radius-radius-radius4);
  padding-right: var(--dl-space-space-p30);
  background-color: #FAFBFE;
}
.contact-us-textinput4 {
  width: 100%;
  height: 100%;
  background-color: transparent;
}
.contact-us-container35 {
  flex: 0 0 auto;
  width: 100%;
  height: 70px;
  display: flex;
  align-items: center;
  border-color: #D1DDF2;
  border-width: 1px;
  padding-left: var(--dl-space-space-p30);
  border-radius: var(--dl-radius-radius-radius4);
  padding-right: var(--dl-space-space-p30);
  background-color: #FAFBFE;
}
.contact-us-textinput5 {
  width: 100%;
  height: 100%;
  background-color: transparent;
}
.contact-us-container36 {
  flex: 0 0 auto;
  width: 100%;
  height: 70px;
  display: flex;
  align-items: center;
  border-color: #D1DDF2;
  border-width: 1px;
  padding-left: var(--dl-space-space-p30);
  border-radius: var(--dl-radius-radius-radius4);
  padding-right: var(--dl-space-space-p30);
  background-color: #FAFBFE;
}
.contact-us-textinput6 {
  width: 100%;
  height: 100%;
  background-color: transparent;
}
.contact-us-container37 {
  flex: 1;
  width: 100%;
  height: 70px;
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-p20);
  border-color: #D1DDF2;
  border-width: 1px;
  padding-left: var(--dl-space-space-p30);
  border-radius: var(--dl-radius-radius-radius4);
  padding-right: var(--dl-space-space-p30);
  background-color: #FAFBFE;
}
.contact-us-textinput7 {
  width: 100%;
  background-color: transparent;
}
.contact-us-container38 {
  gap: 10px;
  fill: var(--dl-color-gray-white);
  flex: 0 0 auto;
  color: var(--dl-color-gray-white);
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  border-radius: 30px;
  justify-content: center;
  background-color: #0066FC;
}
.contact-us-text24 {
  font-size: 18px;
}
.contact-us-icon4 {
  width: 24px;
  height: 24px;
}
@media(max-width: 1440px) {
  .contact-us-container03 {
    width: 1110px;
    padding-left: 100px;
    padding-right: 100px;
  }
  .contact-us-container04 {
    width: 891px;
  }
  .contact-us-text01 {
    font-size: 30px;
    line-height: 45px;
  }
}
@media(max-width: 479px) {
  .contact-us-web {
    display: none;
  }
  .contact-us-mobil {
    flex: 1;
    width: 100%;
    display: flex;
  }
  .contact-us-container24 {
    width: 100%;
  }
  .contact-us-top {
    height: auto;
    background-image: url("/bg/img_9.svg");
    background-repeat: no-repeat;
    background-position: top bottom;
  }
  .contact-us-container25 {
    height: auto;
    align-items: center;
    flex-direction: column;
  }
  .contact-us-container26 {
    margin-top: 70px;
    padding-top: var(--dl-space-space-p40);
    padding-left: var(--dl-space-space-p30);
    padding-right: var(--dl-space-space-p30);
    padding-bottom: var(--dl-space-space-p40);
  }
  .contact-us-container27 {
    align-items: center;
  }
  .contact-us-text19 {
    font-style: normal;
    font-weight: 600;
  }
  .contact-us-text20 {
    font-size: 25px;
    text-align: center;
  }
  .contact-us-text21 {
    color: rgba(255, 255, 255, 0.7);
  }
  .contact-us-content {
    width: 100%;
    padding-bottom: 0px;
  }
  .contact-us-container31 {
    gap: 60px;
    width: 100%;
    padding-top: var(--dl-space-space-p40);
    padding-left: var(--dl-space-space-p30);
    padding-right: var(--dl-space-space-p30);
    padding-bottom: var(--dl-space-space-p40);
  }
  .contact-us-container33 {
    flex: 0 0 auto;
    width: 100%;
    height: auto;
  }
  .contact-us-container37 {
    flex: 0 0 auto;
    height: 130px;
  }
}
