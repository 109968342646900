.mail-cloud-container {
  gap: var(--dl-space-space-halfunit);
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.mail-cloud-web {
  flex: 1;
  width: 100%;
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: column;
}
.mail-cloud-container001 {
  color: var(--dl-color-gray-white);
  width: 100%;
  height: auto;
  display: flex;
  overflow: hidden;
  align-items: center;
  flex-direction: column;
  background-size: cover;
  background-image: url("/bg/img_9.svg");
}
.mail-cloud-container002 {
  gap: 60px;
  flex: 0 0 auto;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-p60);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-p60);
  justify-content: center;
}
.mail-cloud-container003 {
  gap: 20px;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.mail-cloud-container004 {
  color: var(--dl-color-gray-text);
  display: flex;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
  align-items: flex-start;
  padding-top: 5px;
  padding-left: 15px;
  border-radius: 20px;
  padding-right: 15px;
  flex-direction: column;
  padding-bottom: 5px;
  background-image: linear-gradient(315deg, rgba(255, 255, 255, 0.5) 0.00%,rgb(255, 255, 255) 99.00%);
}
.mail-cloud-text {
  font-style: normal;
  font-weight: 600;
}
.mail-cloud-text001 {
  text-align: center;
  text-shadow: 2px 2px rgba(0, 0, 0, 0.2);
}
.mail-cloud-text002 {
  opacity: 1;
  text-align: center;
  text-shadow: 1px 1px rgba(0, 0, 0, 0.3);
}
.mail-cloud-container005 {
  gap: 20px;
  width: 659px;
  display: flex;
  flex-direction: column;
}
.mail-cloud-container006 {
  gap: 10px;
  width: 100%;
  display: flex;
  z-index: 90;
  box-shadow: 0px 0px 10px 0px rgba(66, 66, 66, 0.3);
  align-items: center;
  border-color: var(--dl-color-gray-border-2);
  border-width: 1px;
  flex-direction: column;
  padding-bottom: var(--dl-space-space-p20);
  justify-content: flex-end;
  border-left-width: 0px;
  border-right-width: 0px;
}
.mail-cloud-container007 {
  flex: 0 0 auto;
  width: 100%;
  height: 20px;
  display: flex;
  box-shadow: 0px -5px 10px 0px rgba(66, 66, 66, 0.3);
  align-items: flex-start;
  border-color: var(--dl-color-gray-border);
  border-width: 1px;
  background-color: rgba(243, 243, 243, 0.63);
  border-left-width: 0px;
  border-right-width: 0px;
}
.mail-cloud-text003 {
  opacity: 0.6;
}
.mail-cloud-container008 {
  gap: 40px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.mail-cloud-text004 {
  font-size: 20px;
}
.mail-cloud-text005 {
  font-size: 20px;
}
.mail-cloud-text006 {
  font-size: 20px;
}
.mail-cloud-text007 {
  font-size: 20px;
}
.mail-cloud-text008 {
  font-size: 20px;
}
.mail-cloud-text009 {
  font-size: 20px;
}
.mail-cloud-text010 {
  font-size: 20px;
}
.mail-cloud-text011 {
  font-size: 20px;
}
.mail-cloud-container009 {
  width: 100%;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-p60);
  padding-bottom: var(--dl-space-space-p60);
  justify-content: center;
  background-image: linear-gradient(0deg, rgba(189, 195, 199, 0) 0.00%,rgb(232, 240, 255) 100.00%);
}
.mail-cloud-container010 {
  flex: 0 0 auto;
  width: 80%;
  height: auto;
  display: flex;
  align-items: flex-start;
}
.mail-cloud-container011 {
  gap: 40px;
  flex: 1;
  width: 200px;
  height: auto;
  display: flex;
  align-items: flex-start;
  padding-left: 40px;
  flex-direction: column;
}
.mail-cloud-container012 {
  gap: 10px;
  width: 100%;
  height: auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.mail-cloud-text012 {
  color: rgb(246, 88, 20);
}
.mail-cloud-container013 {
  gap: 20px;
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.mail-cloud-container014 {
  gap: 20px;
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
}
.mail-cloud-container015 {
  fill: var(--dl-color-gray-white);
  flex: 0 0 auto;
  width: 18px;
  height: 18px;
  display: flex;
  padding: 2px;
  align-items: center;
  border-radius: var(--dl-radius-radius-radius4);
  flex-direction: column;
  justify-content: center;
  background-color: var(--dl-color-primary-lila);
}
.mail-cloud-icon {
  width: 100%;
  height: 100%;
}
.mail-cloud-container016 {
  gap: 20px;
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
}
.mail-cloud-container017 {
  fill: var(--dl-color-gray-white);
  flex: 0 0 auto;
  width: 18px;
  height: 18px;
  display: flex;
  padding: 2px;
  align-items: center;
  border-radius: var(--dl-radius-radius-radius4);
  flex-direction: column;
  justify-content: center;
  background-color: var(--dl-color-primary-lila);
}
.mail-cloud-icon02 {
  width: 100%;
  height: 100%;
}
.mail-cloud-container018 {
  gap: 20px;
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
}
.mail-cloud-container019 {
  fill: var(--dl-color-gray-white);
  flex: 0 0 auto;
  width: 18px;
  height: 18px;
  display: flex;
  padding: 2px;
  align-items: center;
  border-radius: var(--dl-radius-radius-radius4);
  flex-direction: column;
  justify-content: center;
  background-color: var(--dl-color-primary-lila);
}
.mail-cloud-icon04 {
  width: 100%;
  height: 100%;
}
.mail-cloud-container020 {
  gap: 20px;
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
}
.mail-cloud-container021 {
  fill: var(--dl-color-gray-white);
  flex: 0 0 auto;
  width: 18px;
  height: 18px;
  display: flex;
  padding: 2px;
  align-items: center;
  border-radius: var(--dl-radius-radius-radius4);
  flex-direction: column;
  justify-content: center;
  background-color: var(--dl-color-primary-lila);
}
.mail-cloud-icon06 {
  width: 100%;
  height: 100%;
}
.mail-cloud-navlink {
  display: contents;
}
.mail-cloud-button-blue {
  text-decoration: none;
}
.mail-cloud-icon08 {
  width: 18px;
  height: 18px;
}
.mail-cloud-container022 {
  flex: 1;
  width: 200px;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.mail-cloud-image {
  width: 656px;
  height: 465px;
  object-fit: cover;
}
.mail-cloud-container023 {
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-p60);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-p60);
  justify-content: center;
}
.mail-cloud-container024 {
  flex: 0 0 auto;
  width: 80%;
  height: auto;
  display: flex;
  align-items: flex-start;
}
.mail-cloud-container025 {
  flex: 1;
  width: 200px;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.mail-cloud-image01 {
  width: 565px;
  height: 465px;
  object-fit: cover;
}
.mail-cloud-container026 {
  gap: 40px;
  flex: 1;
  width: 200px;
  height: auto;
  display: flex;
  align-items: flex-start;
  padding-left: 40px;
  flex-direction: column;
}
.mail-cloud-container027 {
  gap: 10px;
  width: 100%;
  height: auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.mail-cloud-text022 {
  color: rgb(246, 88, 20);
}
.mail-cloud-navlink1 {
  display: contents;
}
.mail-cloud-button-blue1 {
  text-decoration: none;
}
.mail-cloud-icon10 {
  width: 18px;
  height: 18px;
}
.mail-cloud-container028 {
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-p60);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-p60);
  justify-content: center;
}
.mail-cloud-container029 {
  flex: 0 0 auto;
  width: 80%;
  height: auto;
  display: flex;
  align-items: flex-start;
}
.mail-cloud-container030 {
  gap: 40px;
  flex: 1;
  width: 200px;
  height: auto;
  display: flex;
  align-items: flex-start;
  padding-left: 40px;
  flex-direction: column;
}
.mail-cloud-container031 {
  gap: 10px;
  width: 100%;
  height: auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.mail-cloud-text028 {
  color: rgb(246, 88, 20);
}
.mail-cloud-container032 {
  gap: 20px;
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.mail-cloud-container033 {
  gap: 20px;
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
}
.mail-cloud-container034 {
  fill: var(--dl-color-gray-white);
  flex: 0 0 auto;
  width: 18px;
  height: 18px;
  display: flex;
  padding: 2px;
  align-items: center;
  border-radius: var(--dl-radius-radius-radius4);
  flex-direction: column;
  justify-content: center;
  background-color: var(--dl-color-primary-lila);
}
.mail-cloud-icon12 {
  width: 100%;
  height: 100%;
}
.mail-cloud-container035 {
  gap: 20px;
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
}
.mail-cloud-container036 {
  fill: var(--dl-color-gray-white);
  flex: 0 0 auto;
  width: 18px;
  height: 18px;
  display: flex;
  padding: 2px;
  align-items: center;
  border-radius: var(--dl-radius-radius-radius4);
  flex-direction: column;
  justify-content: center;
  background-color: var(--dl-color-primary-lila);
}
.mail-cloud-icon14 {
  width: 100%;
  height: 100%;
}
.mail-cloud-container037 {
  gap: 20px;
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
}
.mail-cloud-container038 {
  fill: var(--dl-color-gray-white);
  flex: 0 0 auto;
  width: 18px;
  height: 18px;
  display: flex;
  padding: 2px;
  align-items: center;
  border-radius: var(--dl-radius-radius-radius4);
  flex-direction: column;
  justify-content: center;
  background-color: var(--dl-color-primary-lila);
}
.mail-cloud-icon16 {
  width: 100%;
  height: 100%;
}
.mail-cloud-container039 {
  gap: 20px;
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
}
.mail-cloud-container040 {
  fill: var(--dl-color-gray-white);
  flex: 0 0 auto;
  width: 18px;
  height: 18px;
  display: flex;
  padding: 2px;
  align-items: center;
  border-radius: var(--dl-radius-radius-radius4);
  flex-direction: column;
  justify-content: center;
  background-color: var(--dl-color-primary-lila);
}
.mail-cloud-icon18 {
  width: 100%;
  height: 100%;
}
.mail-cloud-navlink2 {
  display: contents;
}
.mail-cloud-button-blue2 {
  text-decoration: none;
}
.mail-cloud-icon20 {
  width: 18px;
  height: 18px;
}
.mail-cloud-container041 {
  flex: 1;
  width: 200px;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.mail-cloud-image02 {
  width: 656px;
  height: 465px;
  object-fit: cover;
}
.mail-cloud-container042 {
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-p60);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-p60);
  justify-content: center;
}
.mail-cloud-container043 {
  color: var(--dl-color-gray-white);
  width: 80%;
  height: 326px;
  display: flex;
  overflow: hidden;
  align-items: flex-start;
  border-radius: 30px;
  flex-direction: column;
  background-size: cover;
  background-image: url("/frame%20309-1500w.png");
  background-position: center;
}
.mail-cloud-container044 {
  gap: 30px;
  width: 100%;
  height: 194px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.mail-cloud-text039 {
  opacity: 0.8;
}
.mail-cloud-container045 {
  gap: 40px;
  flex: 1;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}
.mail-cloud-container046 {
  gap: 40px;
  width: 80%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}
.mail-cloud-container047 {
  gap: 10px;
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.mail-cloud-container048 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: center;
}
.mail-cloud-container049 {
  gap: 20px;
  display: flex;
  align-items: center;
}
.mail-cloud-image03 {
  width: 24px;
  height: 32px;
  object-fit: cover;
}
.mail-cloud-text040 {
  font-size: 48px;
  font-style: normal;
  font-family: Poppins;
  font-weight: 600;
  line-height: 51.84px;
}
.mail-cloud-text041 {
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}
.mail-cloud-container050 {
  flex: 0 0 auto;
  width: 1px;
  height: 100px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  background-color: var(--dl-color-gray-white);
}
.mail-cloud-container051 {
  gap: 10px;
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.mail-cloud-container052 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: center;
}
.mail-cloud-container053 {
  gap: 20px;
  display: flex;
  align-items: center;
}
.mail-cloud-image04 {
  width: 24px;
  height: 32px;
  object-fit: cover;
}
.mail-cloud-text042 {
  font-size: 48px;
  font-style: normal;
  font-family: Poppins;
  font-weight: 600;
  line-height: 51.84px;
}
.mail-cloud-text043 {
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}
.mail-cloud-container054 {
  flex: 0 0 auto;
  width: 1px;
  height: 100px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  background-color: var(--dl-color-gray-white);
}
.mail-cloud-container055 {
  gap: 10px;
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.mail-cloud-container056 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: center;
}
.mail-cloud-container057 {
  gap: 20px;
  display: flex;
  align-items: center;
}
.mail-cloud-image05 {
  width: 24px;
  height: 32px;
  object-fit: cover;
}
.mail-cloud-text044 {
  font-size: 48px;
  font-style: normal;
  font-family: Poppins;
  font-weight: 600;
  line-height: 51.84px;
}
.mail-cloud-text045 {
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}
.mail-cloud-container058 {
  flex: 0 0 auto;
  width: 1px;
  height: 100px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  background-color: var(--dl-color-gray-white);
}
.mail-cloud-container059 {
  gap: 10px;
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.mail-cloud-container060 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: center;
}
.mail-cloud-container061 {
  gap: 20px;
  display: flex;
  align-items: center;
}
.mail-cloud-image06 {
  width: 24px;
  height: 32px;
  object-fit: cover;
}
.mail-cloud-text046 {
  font-size: 48px;
  font-style: normal;
  font-family: Poppins;
  font-weight: 600;
  line-height: 51.84px;
}
.mail-cloud-text047 {
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}
.mail-cloud-container062 {
  gap: 60px;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-p60);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-p60);
}
.mail-cloud-container063 {
  width: 80%;
  height: auto;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.mail-cloud-container064 {
  gap: 10px;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.mail-cloud-text048 {
  text-align: center;
}
.mail-cloud-text049 {
  text-align: center;
}
.mail-cloud-container065 {
  gap: 40px;
  width: 80%;
  height: auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.mail-cloud-container066 {
  gap: 40px;
  width: 100%;
  height: auto;
  display: flex;
  align-items: flex-start;
}
.mail-cloud-container067 {
  gap: 20px;
  flex: 1;
  height: 100%;
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-p20);
  border-color: #D1DDF2;
  border-width: 1px;
  padding-left: 40px;
  border-radius: var(--dl-radius-radius-radius8);
  padding-right: 40px;
  flex-direction: column;
  padding-bottom: var(--dl-space-space-p20);
  background-color: var(--dl-color-gray-white);
}
.mail-cloud-container068 {
  width: 100%;
  height: 130px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.mail-cloud-lottie-node {
  width: 120px;
  height: 120px;
}
.mail-cloud-container069 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}
.mail-cloud-text050 {
  text-align: center;
}
.mail-cloud-container070 {
  flex: 0 0 auto;
  width: 100%;
  height: 1px;
  display: flex;
  align-items: flex-start;
  background-color: #DEDEDE;
}
.mail-cloud-container071 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}
.mail-cloud-text051 {
  width: 100%;
  text-align: center;
}
.mail-cloud-container072 {
  gap: 20px;
  flex: 1;
  height: 100%;
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-p20);
  border-color: #D1DDF2;
  border-width: 1px;
  padding-left: 40px;
  border-radius: var(--dl-radius-radius-radius8);
  padding-right: 40px;
  flex-direction: column;
  padding-bottom: var(--dl-space-space-p20);
  background-color: var(--dl-color-gray-white);
}
.mail-cloud-container073 {
  width: 100%;
  height: 130px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.mail-cloud-lottie-node1 {
  width: 85px;
  height: 85px;
}
.mail-cloud-container074 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}
.mail-cloud-text052 {
  text-align: center;
}
.mail-cloud-container075 {
  flex: 0 0 auto;
  width: 100%;
  height: 1px;
  display: flex;
  align-items: flex-start;
  background-color: #DEDEDE;
}
.mail-cloud-container076 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}
.mail-cloud-text053 {
  width: 100%;
  text-align: center;
}
.mail-cloud-container077 {
  gap: 20px;
  flex: 1;
  height: 100%;
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-p20);
  border-color: #D1DDF2;
  border-width: 1px;
  padding-left: 40px;
  border-radius: var(--dl-radius-radius-radius8);
  padding-right: 40px;
  flex-direction: column;
  padding-bottom: var(--dl-space-space-p20);
  background-color: var(--dl-color-gray-white);
}
.mail-cloud-container078 {
  width: 100%;
  height: 130px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.mail-cloud-lottie-node2 {
  width: 180px;
  height: 180px;
}
.mail-cloud-container079 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}
.mail-cloud-text054 {
  text-align: center;
}
.mail-cloud-container080 {
  flex: 0 0 auto;
  width: 100%;
  height: 1px;
  display: flex;
  align-items: flex-start;
  background-color: #DEDEDE;
}
.mail-cloud-container081 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}
.mail-cloud-text055 {
  width: 100%;
  text-align: center;
}
.mail-cloud-container082 {
  width: 100%;
  height: auto;
  display: none;
  align-items: center;
  padding-top: var(--dl-space-space-p60);
  padding-bottom: var(--dl-space-space-p40);
  justify-content: center;
  background-image: linear-gradient(0deg, rgba(189, 195, 199, 0) 0.00%,rgb(232, 240, 255) 100.00%);
}
.mail-cloud-container083 {
  gap: 40px;
  flex: 0 0 auto;
  width: 80%;
  height: 798px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.mail-cloud-container084 {
  width: 100%;
  height: auto;
  display: flex;
  align-items: flex-end;
  flex-direction: row;
}
.mail-cloud-container085 {
  flex: 1;
  width: 200px;
  height: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.mail-cloud-container086 {
  gap: 10px;
  width: 100%;
  height: auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.mail-cloud-text056 {
  color: rgb(246, 88, 20);
}
.mail-cloud-container087 {
  flex: 1;
  width: 200px;
  height: 100%;
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  justify-content: flex-end;
}
.mail-cloud-container088 {
  gap: 5px;
  fill: var(--dl-color-gray-white);
  flex: 0 0 auto;
  color: var(--dl-color-gray-white);
  width: 177px;
  height: 60px;
  display: flex;
  align-items: center;
  border-radius: 30px;
  justify-content: center;
  background-color: #0066FC;
}
.mail-cloud-text058 {
  font-size: 18px;
}
.mail-cloud-icon22 {
  width: 24px;
  height: 24px;
}
.mail-cloud-container089 {
  width: 100%;
  height: 535px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}
.mail-cloud-container090 {
  flex: 0 0 auto;
  width: 475px;
  height: 100%;
  display: flex;
  overflow: hidden;
  align-items: flex-start;
  border-radius: 20px;
  flex-direction: column;
  background-color: var(--dl-color-gray-white);
}
.mail-cloud-container091 {
  width: 100%;
  height: 305px;
  display: flex;
  align-items: flex-start;
  background-size: cover;
  background-image: url("/image-400h.png");
  background-repeat: no-repeat;
  background-position: bottom;
}
.mail-cloud-container092 {
  flex: 1;
  width: 100%;
  display: flex;
  align-items: flex-start;
  padding-left: var(--dl-space-space-p20);
  padding-right: var(--dl-space-space-p20);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-p20);
  justify-content: space-between;
}
.mail-cloud-container093 {
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.mail-cloud-container094 {
  width: 100%;
  height: 70px;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.mail-cloud-text059 {
  opacity: 0.7;
  line-height: 19.36px;
}
.mail-cloud-text060 {
  opacity: 0.7;
  line-height: 19.36px;
}
.mail-cloud-container095 {
  flex: 0 0 auto;
  width: 100%;
  height: 1px;
  display: flex;
  align-items: flex-start;
  background-color: #DEDEDE;
}
.mail-cloud-container096 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.mail-cloud-text061 {
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 36px;
}
.mail-cloud-container097 {
  gap: 5px;
  display: flex;
  align-items: center;
  flex-direction: row;
}
.mail-cloud-text062 {
  font-size: 18px;
  font-style: normal;
  font-family: Poppins;
  font-weight: 600;
  line-height: 27px;
}
.mail-cloud-icon24 {
  width: 24px;
  height: 24px;
}
.mail-cloud-container098 {
  flex: 0 0 auto;
  width: 475px;
  height: 100%;
  display: flex;
  overflow: hidden;
  align-items: flex-start;
  border-radius: 20px;
  flex-direction: column;
  background-color: var(--dl-color-gray-white);
}
.mail-cloud-container099 {
  width: 100%;
  height: 305px;
  display: flex;
  align-items: flex-start;
  background-size: cover;
  background-image: url("/image%20(1");
  background-repeat: no-repeat;
  background-position: bottom;
}
.mail-cloud-container100 {
  flex: 1;
  width: 100%;
  display: flex;
  align-items: flex-start;
  padding-left: var(--dl-space-space-p20);
  padding-right: var(--dl-space-space-p20);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-p20);
  justify-content: space-between;
}
.mail-cloud-container101 {
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.mail-cloud-container102 {
  width: 100%;
  height: 70px;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.mail-cloud-text063 {
  opacity: 0.7;
  line-height: 19.36px;
}
.mail-cloud-text064 {
  opacity: 0.7;
  line-height: 19.36px;
}
.mail-cloud-container103 {
  flex: 0 0 auto;
  width: 100%;
  height: 1px;
  display: flex;
  align-items: flex-start;
  background-color: #DEDEDE;
}
.mail-cloud-container104 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.mail-cloud-text065 {
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 36px;
}
.mail-cloud-container105 {
  gap: 5px;
  display: flex;
  align-items: center;
  flex-direction: row;
}
.mail-cloud-text066 {
  font-size: 18px;
  font-style: normal;
  font-family: Poppins;
  font-weight: 600;
  line-height: 27px;
}
.mail-cloud-icon26 {
  width: 24px;
  height: 24px;
}
.mail-cloud-container106 {
  flex: 0 0 auto;
  width: 475px;
  height: 100%;
  display: flex;
  overflow: hidden;
  align-items: flex-start;
  border-radius: 20px;
  flex-direction: column;
  background-color: var(--dl-color-gray-white);
}
.mail-cloud-container107 {
  width: 100%;
  height: 305px;
  display: flex;
  align-items: flex-start;
  background-size: cover;
  background-image: url("/image%20(2");
  background-repeat: no-repeat;
  background-position: bottom;
}
.mail-cloud-container108 {
  flex: 1;
  width: 100%;
  display: flex;
  align-items: flex-start;
  padding-left: var(--dl-space-space-p20);
  padding-right: var(--dl-space-space-p20);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-p20);
  justify-content: space-between;
}
.mail-cloud-container109 {
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.mail-cloud-container110 {
  width: 100%;
  height: 70px;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.mail-cloud-text067 {
  opacity: 0.7;
  line-height: 19.36px;
}
.mail-cloud-text068 {
  opacity: 0.7;
  line-height: 19.36px;
}
.mail-cloud-container111 {
  flex: 0 0 auto;
  width: 100%;
  height: 1px;
  display: flex;
  align-items: flex-start;
  background-color: #DEDEDE;
}
.mail-cloud-container112 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.mail-cloud-text069 {
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 36px;
}
.mail-cloud-container113 {
  gap: 5px;
  display: flex;
  align-items: center;
  flex-direction: row;
}
.mail-cloud-text070 {
  font-size: 18px;
  font-style: normal;
  font-family: Poppins;
  font-weight: 600;
  line-height: 27px;
}
.mail-cloud-icon28 {
  width: 24px;
  height: 24px;
}
.mail-cloud-container114 {
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-p40);
  padding-bottom: var(--dl-space-space-p40);
  background-size: cover;
  justify-content: center;
  background-image: url("/rectangle%20166-1500w.png");
}
.mail-cloud-container115 {
  flex: 0 0 auto;
  width: 80%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.mail-cloud-container116 {
  gap: 20px;
  flex: 1;
  color: var(--dl-color-gray-white);
  height: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
.mail-cloud-text072 {
  opacity: 0.8;
}
.mail-cloud-container117 {
  gap: 10px;
  width: 526px;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.mail-cloud-container118 {
  gap: 5px;
  flex: 0 0 auto;
  color: var(--dl-color-gray-white);
  width: 100%;
  height: 45px;
  display: flex;
  align-items: center;
  padding-left: var(--dl-space-space-p20);
  border-radius: 35px;
  flex-direction: row;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.23);
}
.mail-cloud-textinput {
  width: 100%;
  height: 100%;
  background-color: transparent;
}
.mail-cloud-container119 {
  gap: 10px;
  flex: 0 0 auto;
  width: 100%;
  height: 45px;
  display: flex;
  align-items: center;
  border-radius: 35px;
  flex-direction: row;
  justify-content: center;
  background-color: var(--dl-color-gray-white);
}
.mail-cloud-text076 {
  font-size: 18px;
  line-height: 22px;
}
.mail-cloud-icon30 {
  width: 24px;
  height: 24px;
}
.mail-cloud-mobil {
  flex: 0 0 auto;
  width: 200px;
  height: 100px;
  display: none;
  align-items: flex-start;
  flex-direction: column;
}
.mail-cloud-container120 {
  display: flex;
  position: absolute;
}
.mail-cloud-top {
  width: 100%;
  display: flex;
  position: relative;
}
.mail-cloud-container121 {
  display: flex;
}
.mail-cloud-container122 {
  width: 100%;
  display: flex;
}
.mail-cloud-container123 {
  gap: 20px;
  width: 100%;
  height: auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.mail-cloud-container124 {
  color: var(--dl-color-gray-text);
  display: flex;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
  align-items: flex-start;
  padding-top: 5px;
  padding-left: 15px;
  border-radius: 20px;
  padding-right: 15px;
  flex-direction: column;
  padding-bottom: 5px;
  background-image: linear-gradient(315deg, rgba(255, 255, 255, 0.5) 0.00%,rgb(255, 255, 255) 99.00%);
}
.mail-cloud-text077 {
  font-style: normal;
  font-weight: 600;
}
.mail-cloud-container125 {
  gap: 20px;
  color: white;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.mail-cloud-text078 {
  text-shadow: 2px 2px rgba(0, 0, 0, 0.2);
}
.mail-cloud-content {
  flex: 0 0 auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.mail-cloud-container126 {
  flex: 0 0 auto;
  width: 100%;
  height: 20px;
  display: flex;
  box-shadow: 0px -5px 10px 0px rgba(66, 66, 66, 0.3);
  align-items: flex-start;
  border-color: var(--dl-color-gray-border);
  border-width: 1px;
  background-color: rgba(243, 243, 243, 0.63);
  border-left-width: 0px;
  border-right-width: 0px;
}
.mail-cloud-container127 {
  flex: 0 0 auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.mail-cloud-container128 {
  gap: 10px;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.mail-cloud-text080 {
  color: rgb(246, 88, 20);
}
.mail-cloud-text081 {
  text-align: center;
}
.mail-cloud-text082 {
  text-align: center;
}
.mail-cloud-image07 {
  width: 100%;
  height: auto;
  object-fit: cover;
}
.mail-cloud-container129 {
  gap: 20px;
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.mail-cloud-container130 {
  gap: 20px;
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
}
.mail-cloud-container131 {
  fill: var(--dl-color-gray-white);
  flex: 0 0 auto;
  width: 18px;
  height: 18px;
  display: flex;
  padding: 2px;
  align-items: center;
  border-radius: var(--dl-radius-radius-radius4);
  flex-direction: column;
  justify-content: center;
  background-color: var(--dl-color-primary-lila);
}
.mail-cloud-icon32 {
  width: 100%;
  height: 100%;
}
.mail-cloud-container132 {
  gap: 20px;
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
}
.mail-cloud-container133 {
  fill: var(--dl-color-gray-white);
  flex: 0 0 auto;
  width: 18px;
  height: 18px;
  display: flex;
  padding: 2px;
  align-items: center;
  border-radius: var(--dl-radius-radius-radius4);
  flex-direction: column;
  justify-content: center;
  background-color: var(--dl-color-primary-lila);
}
.mail-cloud-icon34 {
  width: 100%;
  height: 100%;
}
.mail-cloud-container134 {
  gap: 20px;
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
}
.mail-cloud-container135 {
  fill: var(--dl-color-gray-white);
  flex: 0 0 auto;
  width: 18px;
  height: 18px;
  display: flex;
  padding: 2px;
  align-items: center;
  border-radius: var(--dl-radius-radius-radius4);
  flex-direction: column;
  justify-content: center;
  background-color: var(--dl-color-primary-lila);
}
.mail-cloud-icon36 {
  width: 100%;
  height: 100%;
}
.mail-cloud-container136 {
  gap: 20px;
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
}
.mail-cloud-container137 {
  fill: var(--dl-color-gray-white);
  flex: 0 0 auto;
  width: 18px;
  height: 18px;
  display: flex;
  padding: 2px;
  align-items: center;
  border-radius: var(--dl-radius-radius-radius4);
  flex-direction: column;
  justify-content: center;
  background-color: var(--dl-color-primary-lila);
}
.mail-cloud-icon38 {
  width: 100%;
  height: 100%;
}
.mail-cloud-container138 {
  flex: 0 0 auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.mail-cloud-container139 {
  gap: 10px;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.mail-cloud-text087 {
  color: rgb(246, 88, 20);
}
.mail-cloud-text088 {
  text-align: center;
}
.mail-cloud-text089 {
  text-align: center;
}
.mail-cloud-image08 {
  width: 656px;
  height: 465px;
  object-fit: cover;
}
.mail-cloud-container140 {
  flex: 0 0 auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.mail-cloud-container141 {
  gap: 10px;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.mail-cloud-text090 {
  color: rgb(246, 88, 20);
}
.mail-cloud-text091 {
  text-align: center;
}
.mail-cloud-text092 {
  text-align: center;
}
.mail-cloud-image09 {
  width: 100%;
  height: auto;
  object-fit: cover;
}
.mail-cloud-container142 {
  gap: 20px;
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.mail-cloud-container143 {
  gap: 20px;
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
}
.mail-cloud-container144 {
  fill: var(--dl-color-gray-white);
  flex: 0 0 auto;
  width: 18px;
  height: 18px;
  display: flex;
  padding: 2px;
  align-items: center;
  border-radius: var(--dl-radius-radius-radius4);
  flex-direction: column;
  justify-content: center;
  background-color: var(--dl-color-primary-lila);
}
.mail-cloud-icon40 {
  width: 100%;
  height: 100%;
}
.mail-cloud-container145 {
  gap: 20px;
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
}
.mail-cloud-container146 {
  fill: var(--dl-color-gray-white);
  flex: 0 0 auto;
  width: 18px;
  height: 18px;
  display: flex;
  padding: 2px;
  align-items: center;
  border-radius: var(--dl-radius-radius-radius4);
  flex-direction: column;
  justify-content: center;
  background-color: var(--dl-color-primary-lila);
}
.mail-cloud-icon42 {
  width: 100%;
  height: 100%;
}
.mail-cloud-container147 {
  gap: 20px;
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
}
.mail-cloud-container148 {
  fill: var(--dl-color-gray-white);
  flex: 0 0 auto;
  width: 18px;
  height: 18px;
  display: flex;
  padding: 2px;
  align-items: center;
  border-radius: var(--dl-radius-radius-radius4);
  flex-direction: column;
  justify-content: center;
  background-color: var(--dl-color-primary-lila);
}
.mail-cloud-icon44 {
  width: 100%;
  height: 100%;
}
.mail-cloud-container149 {
  gap: 20px;
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
}
.mail-cloud-container150 {
  fill: var(--dl-color-gray-white);
  flex: 0 0 auto;
  width: 18px;
  height: 18px;
  display: flex;
  padding: 2px;
  align-items: center;
  border-radius: var(--dl-radius-radius-radius4);
  flex-direction: column;
  justify-content: center;
  background-color: var(--dl-color-primary-lila);
}
.mail-cloud-icon46 {
  width: 100%;
  height: 100%;
}
.mail-cloud-container151 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: flex-start;
}
.mail-cloud-container152 {
  fill: var(--dl-color-gray-white);
  flex: 0 0 auto;
  color: white;
  display: flex;
  overflow: hidden;
  align-items: flex-start;
  flex-direction: column;
}
.mail-cloud-container153 {
  gap: 10px;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.mail-cloud-text097 {
  text-align: center;
}
.mail-cloud-text098 {
  text-align: center;
}
.mail-cloud-container154 {
  flex: 0 0 auto;
  width: 200px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.mail-cloud-container155 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: flex-start;
}
.mail-cloud-container156 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.mail-cloud-text099 {
  font-size: 48px;
  font-style: normal;
  font-family: Poppins;
  font-weight: 600;
  line-height: 40px;
}
.mail-cloud-container157 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.mail-cloud-text101 {
  font-size: 48px;
  font-style: normal;
  font-family: Poppins;
  font-weight: 600;
  line-height: 40px;
}
.mail-cloud-container158 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: flex-start;
}
.mail-cloud-container159 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.mail-cloud-text103 {
  font-size: 48px;
  font-style: normal;
  font-family: Poppins;
  font-weight: 600;
  line-height: 40px;
}
.mail-cloud-container160 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.mail-cloud-text105 {
  font-size: 48px;
  font-style: normal;
  font-family: Poppins;
  font-weight: 600;
  line-height: 40px;
}
.mail-cloud-container161 {
  flex: 0 0 auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.mail-cloud-container162 {
  gap: 10px;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.mail-cloud-text107 {
  color: rgb(246, 88, 20);
}
.mail-cloud-text108 {
  text-align: center;
}
.mail-cloud-text109 {
  text-align: center;
}
.mail-cloud-container163 {
  gap: 20px;
  flex: 1;
  height: 100%;
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-p20);
  border-color: #D1DDF2;
  border-width: 1px;
  padding-left: 40px;
  border-radius: var(--dl-radius-radius-radius8);
  padding-right: 40px;
  flex-direction: column;
  padding-bottom: var(--dl-space-space-p20);
  background-color: var(--dl-color-gray-white);
}
.mail-cloud-container164 {
  width: 100%;
  height: 130px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.mail-cloud-lottie-node3 {
  width: 120px;
  height: 120px;
}
.mail-cloud-container165 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}
.mail-cloud-text110 {
  text-align: center;
}
.mail-cloud-container166 {
  flex: 0 0 auto;
  width: 100%;
  height: 1px;
  display: flex;
  align-items: flex-start;
  background-color: #DEDEDE;
}
.mail-cloud-container167 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}
.mail-cloud-text111 {
  width: 100%;
  text-align: center;
}
.mail-cloud-container168 {
  gap: 20px;
  flex: 1;
  height: 100%;
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-p20);
  border-color: #D1DDF2;
  border-width: 1px;
  padding-left: 40px;
  border-radius: var(--dl-radius-radius-radius8);
  padding-right: 40px;
  flex-direction: column;
  padding-bottom: var(--dl-space-space-p20);
  background-color: var(--dl-color-gray-white);
}
.mail-cloud-container169 {
  width: 100%;
  height: 130px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.mail-cloud-lottie-node4 {
  width: 85px;
  height: 85px;
}
.mail-cloud-container170 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}
.mail-cloud-text112 {
  text-align: center;
}
.mail-cloud-container171 {
  flex: 0 0 auto;
  width: 100%;
  height: 1px;
  display: flex;
  align-items: flex-start;
  background-color: #DEDEDE;
}
.mail-cloud-container172 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}
.mail-cloud-text113 {
  width: 100%;
  text-align: center;
}
.mail-cloud-container173 {
  gap: 20px;
  flex: 1;
  height: 100%;
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-p20);
  border-color: #D1DDF2;
  border-width: 1px;
  padding-left: 40px;
  border-radius: var(--dl-radius-radius-radius8);
  padding-right: 40px;
  flex-direction: column;
  padding-bottom: var(--dl-space-space-p20);
  background-color: var(--dl-color-gray-white);
}
.mail-cloud-container174 {
  width: 100%;
  height: 130px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.mail-cloud-lottie-node5 {
  width: 180px;
  height: 180px;
}
.mail-cloud-container175 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}
.mail-cloud-text114 {
  text-align: center;
}
.mail-cloud-container176 {
  flex: 0 0 auto;
  width: 100%;
  height: 1px;
  display: flex;
  align-items: flex-start;
  background-color: #DEDEDE;
}
.mail-cloud-container177 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}
.mail-cloud-text115 {
  width: 100%;
  text-align: center;
}
@media(max-width: 1440px) {
  .mail-cloud-container003 {
    width: 564px;
  }
  .mail-cloud-text001 {
    font-size: 30px;
    line-height: 45px;
  }
  .mail-cloud-container090 {
    width: 350px;
  }
  .mail-cloud-container098 {
    width: 350px;
  }
  .mail-cloud-container106 {
    width: 350px;
  }
}
@media(max-width: 991px) {
  .mail-cloud-web {
    display: none;
  }
}
@media(max-width: 479px) {
  .mail-cloud-web {
    display: none;
  }
  .mail-cloud-mobil {
    flex: 1;
    width: 100%;
    display: flex;
  }
  .mail-cloud-container120 {
    width: 100%;
  }
  .mail-cloud-top {
    height: auto;
    background-image: url("/bg/img_9.svg");
    background-repeat: no-repeat;
    background-position: top bottom;
  }
  .mail-cloud-container121 {
    height: auto;
    align-items: center;
    flex-direction: column;
  }
  .mail-cloud-container122 {
    margin-top: 70px;
    padding-top: var(--dl-space-space-p40);
    padding-left: var(--dl-space-space-p30);
    padding-right: var(--dl-space-space-p30);
    padding-bottom: var(--dl-space-space-p40);
  }
  .mail-cloud-container123 {
    align-items: center;
  }
  .mail-cloud-text077 {
    font-style: normal;
    font-weight: 600;
  }
  .mail-cloud-text078 {
    font-size: 25px;
    text-align: center;
  }
  .mail-cloud-text079 {
    color: rgba(255, 255, 255, 0.7);
  }
  .mail-cloud-content {
    width: 100%;
    padding-bottom: 0px;
  }
  .mail-cloud-container127 {
    width: 100%;
    padding-top: var(--dl-space-space-p40);
    padding-left: var(--dl-space-space-p30);
    padding-right: var(--dl-space-space-p30);
    padding-bottom: var(--dl-space-space-p40);
  }
  .mail-cloud-container138 {
    width: 100%;
    padding-top: var(--dl-space-space-p40);
    padding-left: var(--dl-space-space-p30);
    padding-right: var(--dl-space-space-p30);
    padding-bottom: var(--dl-space-space-p40);
  }
  .mail-cloud-container139 {
    width: 100%;
  }
  .mail-cloud-text087 {
    color: rgb(246, 88, 20);
  }
  .mail-cloud-text088 {
    line-height: 30px;
  }
  .mail-cloud-image08 {
    width: 100%;
    height: auto;
  }
  .mail-cloud-container140 {
    width: 100%;
    padding-top: var(--dl-space-space-p40);
    padding-left: var(--dl-space-space-p30);
    padding-right: var(--dl-space-space-p30);
    padding-bottom: var(--dl-space-space-p40);
  }
  .mail-cloud-text090 {
    color: rgb(246, 88, 20);
  }
  .mail-cloud-container151 {
    padding-top: var(--dl-space-space-p40);
    padding-left: var(--dl-space-space-p30);
    padding-right: var(--dl-space-space-p30);
    padding-bottom: var(--dl-space-space-p40);
  }
  .mail-cloud-container152 {
    gap: 20px;
    flex: 1;
    padding: var(--dl-space-space-p20);
    align-items: center;
    border-radius: 30px;
    background-size: cover;
    background-image: url("/frame%20309-1500w.png");
  }
  .mail-cloud-container153 {
    padding-bottom: var(--dl-space-space-p20);
  }
  .mail-cloud-container154 {
    gap: 20px;
    width: 100%;
    align-items: center;
  }
  .mail-cloud-container155 {
    gap: 20px;
  }
  .mail-cloud-container156 {
    flex: 1;
    align-items: center;
    padding-top: 10px;
    border-radius: var(--dl-radius-radius-radius8);
    padding-bottom: 10px;
    background-color: rgba(255, 255, 255, 0.2);
  }
  .mail-cloud-text099 {
    font-size: 25px;
  }
  .mail-cloud-text100 {
    font-size: 12px;
  }
  .mail-cloud-container157 {
    flex: 1;
    align-items: center;
    padding-top: 10px;
    border-radius: var(--dl-radius-radius-radius8);
    padding-bottom: 10px;
    background-color: rgba(255, 255, 255, 0.2);
  }
  .mail-cloud-text101 {
    font-size: 25px;
  }
  .mail-cloud-text102 {
    font-size: 12px;
  }
  .mail-cloud-container158 {
    gap: 20px;
  }
  .mail-cloud-container159 {
    flex: 1;
    align-items: center;
    padding-top: 10px;
    border-radius: var(--dl-radius-radius-radius8);
    padding-bottom: 10px;
    background-color: rgba(255, 255, 255, 0.2);
  }
  .mail-cloud-text103 {
    font-size: 25px;
    font-style: normal;
    font-family: Poppins;
    font-weight: 600;
    line-height: 40px;
  }
  .mail-cloud-text104 {
    font-size: 12px;
  }
  .mail-cloud-container160 {
    flex: 1;
    align-items: center;
    padding-top: 10px;
    border-radius: var(--dl-radius-radius-radius8);
    padding-bottom: 10px;
    background-color: rgba(255, 255, 255, 0.2);
  }
  .mail-cloud-text105 {
    font-size: 25px;
    font-style: normal;
    font-family: Poppins;
    font-weight: 600;
    line-height: 40px;
  }
  .mail-cloud-text106 {
    font-size: 12px;
  }
  .mail-cloud-container161 {
    gap: 40px;
    width: 100%;
    padding-top: var(--dl-space-space-p40);
    padding-left: var(--dl-space-space-p30);
    padding-right: var(--dl-space-space-p30);
    padding-bottom: var(--dl-space-space-p40);
  }
  .mail-cloud-container162 {
    width: 100%;
  }
  .mail-cloud-text107 {
    color: rgb(246, 88, 20);
  }
  .mail-cloud-text108 {
    line-height: 30px;
  }
  .mail-cloud-container163 {
    flex: 0 0 auto;
    width: 100%;
    height: auto;
  }
  .mail-cloud-container168 {
    flex: 0 0 auto;
    width: 100%;
    height: auto;
  }
  .mail-cloud-container173 {
    flex: 0 0 auto;
    width: 100%;
    height: auto;
  }
}
