.menu-bottom-mobil-component-menu-bottom-mobil-component {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  position: relative;
  align-items: flex-start;
}
.menu-bottom-mobil-component-container {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: flex-start;
}
.menu-bottom-mobil-component-container1 {
  flex: 0 0 auto;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  border-radius: var(--dl-radius-radius-radius8);
  justify-content: center;
}
.menu-bottom-mobil-component-navlink {
  display: contents;
}
.menu-bottom-mobil-component-image {
  width: 100%;
  object-fit: cover;
  text-decoration: none;
}
.menu-bottom-mobil-component-container2 {
  flex: 0 0 auto;
  color: white;
  width: 200px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.menu-bottom-mobil-component-container3 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.menu-bottom-mobil-component-navlink1 {
  cursor: pointer;
  opacity: 0.4;
  transition: 0.1s;
  text-decoration: none;
}
.menu-bottom-mobil-component-navlink1:hover {
  opacity: 1;
}
.menu-bottom-mobil-component-navlink2 {
  cursor: pointer;
  opacity: 0.4;
  transition: 0.1s;
  text-decoration: none;
}
.menu-bottom-mobil-component-navlink2:hover {
  opacity: 1;
}
.menu-bottom-mobil-component-navlink3 {
  cursor: pointer;
  opacity: 0.4;
  transition: 0.1s;
  text-decoration: none;
}
.menu-bottom-mobil-component-navlink3:hover {
  opacity: 1;
}
.menu-bottom-mobil-component-navlink4 {
  cursor: pointer;
  opacity: 0.4;
  transition: 0.1s;
  text-decoration: none;
}
.menu-bottom-mobil-component-navlink4:hover {
  opacity: 1;
}
.menu-bottom-mobil-component-container4 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.menu-bottom-mobil-component-navlink5 {
  cursor: pointer;
  opacity: 0.4;
  transition: 0.1s;
  text-decoration: none;
}
.menu-bottom-mobil-component-navlink5:hover {
  opacity: 1;
}
.menu-bottom-mobil-component-navlink6 {
  cursor: pointer;
  opacity: 0.4;
  transition: 0.1s;
  text-decoration: none;
}
.menu-bottom-mobil-component-navlink6:hover {
  opacity: 1;
}
.menu-bottom-mobil-component-navlink7 {
  cursor: pointer;
  opacity: 0.4;
  transition: 0.1s;
  text-decoration: none;
}
.menu-bottom-mobil-component-navlink7:hover {
  opacity: 1;
}
.menu-bottom-mobil-component-container5 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.menu-bottom-mobil-component-container6 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.menu-bottom-mobil-component-container7 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
@media(max-width: 1440px) {
  .menu-bottom-mobil-component-container1 {
    width: 61px;
    height: 61px;
    align-items: center;
    justify-content: center;
  }
  .menu-bottom-mobil-component-image {
    width: 80%;
    height: 80%;
  }
}
@media(max-width: 479px) {
  .menu-bottom-mobil-component-menu-bottom-mobil-component {
    height: auto;
    flex-direction: column;
    background-color: #100F0F;
  }
  .menu-bottom-mobil-component-container {
    gap: 10px;
    align-items: center;
    padding-top: var(--dl-space-space-p20);
    border-color: rgba(209, 221, 242, 0.3);
    padding-left: var(--dl-space-space-p30);
    padding-right: var(--dl-space-space-p30);
    flex-direction: column;
    padding-bottom: var(--dl-space-space-p20);
    background-color: rgba(255, 255, 255, 0.1);
  }
  .menu-bottom-mobil-component-container1 {
    width: 40px;
    height: 40px;
    background-color: transparent;
    background-image: linear-gradient(315deg, rgba(255, 255, 255, 0.5) 0.00%,rgb(255, 255, 255) 99.00%);
  }
  .menu-bottom-mobil-component-text {
    color: rgb(255, 255, 255);
    width: 100%;
    text-align: center;
  }
  .menu-bottom-mobil-component-container2 {
    gap: 40px;
    flex: 1;
    width: 100%;
    align-items: center;
    padding-top: var(--dl-space-space-p40);
    justify-content: center;
  }
  .menu-bottom-mobil-component-container3 {
    gap: 20px;
    align-items: center;
  }
  .menu-bottom-mobil-component-text03 {
    font-weight: 600;
  }
  .menu-bottom-mobil-component-container4 {
    gap: 20px;
    align-items: center;
  }
  .menu-bottom-mobil-component-text04 {
    font-weight: 600;
  }
  .menu-bottom-mobil-component-container5 {
    width: 100%;
  }
  .menu-bottom-mobil-component-container6 {
    gap: 20px;
    width: 100%;
    align-items: center;
    padding-top: var(--dl-space-space-p20);
    border-color: rgba(209, 221, 242, 0.3);
    border-width: 1px;
    padding-bottom: var(--dl-space-space-p20);
    background-color: rgba(255, 255, 255, 0.1);
    border-left-width: 0px;
    border-right-width: 0px;
  }
  .menu-bottom-mobil-component-text05 {
    font-weight: 600;
  }
  .menu-bottom-mobil-component-text06 {
    opacity: 0.4;
    text-align: center;
  }
  .menu-bottom-mobil-component-text07 {
    opacity: 0.4;
    text-align: center;
  }
  .menu-bottom-mobil-component-container7 {
    gap: 20px;
    width: 100%;
    align-items: center;
    padding-top: var(--dl-space-space-p20);
    padding-bottom: var(--dl-space-space-p20);
  }
}
